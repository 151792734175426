import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild,inject } from '@angular/core';
import {
  buildResultList,
  Result,
  ResultList,
  loadQueryActions,
  loadSearchActions,
  loadSearchAnalyticsActions,
  buildQuickview,
  FacetState,
  Facet,
  FacetSearchState,
  FacetSearch,
  buildSearchEngine,
  getOrganizationEndpoints,
  ResultListProps,
  ResultListOptions,
  loadAdvancedSearchQueryActions,
  loadFacetSetActions,
  SearchEngine,
  FacetValueState,
  FacetValue,
  ToggleSelectFacetValueActionCreatorPayload,
} from '@coveo/headless';
import { CONFIG } from 'src/environments/environment.development';
import { EngineService } from '../engine.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { OnedriveService } from '../services/onedrive.service';
import { OneDriveRegistrationService } from '../services/one-drive-registration.service';
import { Unsubscribe } from '@coveo/atomic-angular';
// import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../shared.service';
import { ResultsListDataService } from '../results-list-data.service';
import { CollectionsService } from '../collections.service';
import { AuthService } from '../auth.service';
import { CollectionsComponent } from '../collections/collections.component';
import { CryptoService } from '../crypto.service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import {DOCUMENT} from '@angular/common';
import { Customeventlogging } from '../customevent-logging.service';
import { AnyFacetSetState } from '@coveo/headless/dist/definitions/features/facets/generic/interfaces/generic-facet-section';
import { CookieService } from 'ngx-cookie-service';
import { ViewpageService } from '../viewpage.service';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.css'],
  
})
export class ResultListComponent implements OnInit, OnDestroy, AfterViewInit {
  selectionFreezeTitle:any = CONFIG.MULTI_SELECT.SELECTION_FREEZE_TITLE;
  selectionFreezeBody:any = CONFIG.MULTI_SELECT.SELECTION_FREEZE_BODY;
  selectionExceedTitle:any = CONFIG.MULTI_SELECT.SELECTION_EXCEED_TITLE;
  selectionExceedBody:any =  CONFIG.MULTI_SELECT.SELECTION_EXCEED_BODY;

  private dom:any = inject(DOCUMENT);
  private headlessResultList!: ResultList;
  private facet!: FacetSearch;
  public queryButtonText: String = 'Query Result';
  imageUrl = '../../assets/merck-assets/Frame.png';
  @ViewChild('dataContainer') dataContainer!: ElementRef;
  feature = "";
  showPopup = false;
  showPopupCollection = false;
  showPopupCollectionView = false;
  title: string = "";
  body: string = "";
  res: any = null;
  searchUID : any;
  oneDriveDownloadOperation:boolean = false;
  backToResults: boolean = false;
  onedrive_icon_unfilled = CONFIG.ONEDRIVE.UNFILLED_ICON;
  onedrive_icon_filled = CONFIG.ONEDRIVE.FILLED_ICON;

  collection_icon_unfilled = CONFIG.COLLECTIONS.UNFILLED_ICON
  collection_icon_filled = CONFIG.COLLECTIONS.FILLED_ICON


  oneDriveDocs: any = [];
  private resultListUnsubscribe!: Unsubscribe;
  closeflagpopup = false;
  collectionslist:any;
  userId:any;
  isRockyMountainUser = false;
  filedownloaderror = false;

  @ViewChild(CollectionsComponent) collectionComp!: CollectionsComponent

  public constructor(
    private engineService: EngineService,
    protected sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private router: Router,
    private onedrive: OnedriveService,
    // public dialog: MatDialog
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    // public dialog: MatDialog

    private resultListDataService: ResultsListDataService,
    private sharedService: SharedService,
    private collectionService:CollectionsService,
    private auth : AuthService,
    private cryptoService : CryptoService,
    private cookieService: CookieServiceWrapper,
    private eventLoggingService : Customeventlogging,
    private cookie:CookieService,
    @Inject(DOCUMENT) document: Document,
    private viewPageService: ViewpageService
  ) { 
    if(sessionStorage.getItem("isRockyMountainUser") == null || sessionStorage.getItem("isRockyMountainUser") == "null" ){
      this.router.navigate(['/auth']);
     }
     else{
      var userType = sessionStorage.getItem("isRockyMountainUser");
       if(userType == "true"){
            this.isRockyMountainUser = true;
       }
       else{
        this.isRockyMountainUser = false;
       }
     }
    localStorage.setItem('selectedDocuments', JSON.stringify(this.selectedDocs));
    this.sharedService.getData().subscribe(data => {
      this.selectedDocs = [];
      this.feature = "";
      this.showPopup = true;
      this.title = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_TITLE;
      this.body = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_SUCCESS;
//       this.feature = '';
//       this.showPopup = true;
      this.closeflagpopup = true;
      
      this.getOneDriveDocsFromServer().then(function(){
        const engine = engineService.getEngibeObj();
        const { executeSearch } = loadSearchActions(engine);
        const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
        engine.dispatch(executeSearch(logSearchboxSubmit()));
      })
    });




    this.sharedService.getCollectionUpdate().subscribe(data => {
      this.selectedDocs = [];
      this.getCollectionsDocsFromServer().then(function(){
        const engine = engineService.getEngibeObj();
        const { executeSearch } = loadSearchActions(engine);
        const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
        engine.dispatch(executeSearch(logSearchboxSubmit()));
      })
    });


    this.sharedService.getclearAll().subscribe(data=>{
      // console.log("called");
      this.backToResults = false;	 
    });


  }


  isExpanded:boolean = false;
  toggleReadMore(){
    this.isExpanded = !this.isExpanded;
  }
  removeHTMLContent(value: string): number {
    if (!value) {
      return 0; // or handle appropriately for your use case
    }
    return value.replace(/<\/?[^>]+(>|$)/g,"").length;
  }

  removeHTMLContentDescription(value: string): number {
    if (!value) {
      return 0; // or handle appropriately for your use case
    }
    return value.replace(/<\/?[^>]+(>|$)/g,"").length;
  }

  shouldShowReadMoreVEES(content: string): boolean {
    return this.removeHTMLContentVEES(content) > 50;
  }
  
  removeHTMLContentVEES(value: string): number {
    if (!value) {
      return 0; // or handle appropriately for your use case
    }
    const strippedContent = value.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    return strippedContent.length;  }

  private oid : string = '';

  public ngOnInit(): void {
    console.log("oid", this.cookie.get('U2FsdGVkX1%2BPb2z8%2Bl6Nv6oX7zoFizU5vb49LUhSQ0B%2BGaPFsFGhPgp2j%2FhqiNhZJdAAheQUWa%2B7jKmnnysu6w%3D%3D'))

    this.userId = this.auth.getUserId();
    this.searchUID = localStorage.getItem('searchID');
    this.oid = this.cookie.get('oid');
    // console.log("search id in result list", this.searchUID);
    // this.initializeController();
    this.getOneDriveDocsFromServer().then((data)=>{
      this.getCollectionsDocsFromServer().then((res)=>{
        
        const engine = this.engineService.getEngibeObj();
        // const { updateQuery } = loadQueryActions(engine);
        const { executeSearch } = loadSearchActions(engine);
        const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
        // engine.dispatch(updateQuery({ q: result.title }));
        engine.dispatch(executeSearch(logSearchboxSubmit()));
      });
    });

  }

  isApprovedForUse(contentitemstatus: string[]): string {
    return contentitemstatus && contentitemstatus.includes('Approved for Use') ? 'Internal Use Only' : 'N/A';
}

  ngAfterViewInit(): void {
    this.initializeController();
  }
  ngOnDestroy(): void {
    // this.resultListUnsubscribe();
  }


 

  getQueryPayload() {
    // alert("CALLED");
    // const engine = this.engineService.getEngibeObj();
    // const queryPayload = engine.state.facetSet;
    // const facetState = engine.state.facetSet;
    // this.facet.clear();
    // // console.log('Query Payload:', queryPayload);



    const engine = buildSearchEngine({
      configuration: {
        preprocessRequest: (request, clientOrigin) => {

          // request.facets

            const req = request.body?.toString();
            alert(JSON.stringify(req));
            return request;
        },
        organizationEndpoints: getOrganizationEndpoints(
         this.oid
        ),
        // search:searchConfigurationOptions,
        organizationId: this.oid,
        accessToken: '********************************',
      }

      // configuration: {
      //   preprocessRequest: (request, clientOrigin) => {
      //     if (clientOrigin === 'searchApiFetch') {
      //       const body = JSON.parse(request.body);
      //       // E.g., modify facet requests
      //       // body.facets = [...];
      //       request.body = JSON.stringify(body);
      //     }
     
      //     if (clientOrigin === 'analyticsFetch') {
      //       // E.g., send data to a third party
      //     }
     
      //     return request;
      //   },
      // },
    });



  }

  public get results(): Result[] {
          // // console.log("results", this.headlessResultList?.state.results);
          // this.resultListDataService.setResults(this.headlessResultList?.state.results);
          //return this.headlessResultList?.state.results;

    return this.headlessResultList?.state.results;
  }

  private async initializeController() {

    this.showAllSelectedFlag = false;
    // this.cookieService.set('selectedDocuments',"");
    localStorage.setItem('selectedDocuments',"");

    // this.getOneDriveDocsFromServer().then((data)=>{
      // this.getCollectionsDocsFromServer().then((res)=>{


        const engine = this.engineService.getEngibeObj();

    var fields:string[];
    fields = ["spouniqueid"];

    var options : ResultListOptions = {
      fieldsToInclude:fields
    }
    var resultListProps : ResultListProps = {
      options:options
    }

    this.headlessResultList = buildResultList(engine,resultListProps);
    this.resultListUnsubscribe = this.headlessResultList.subscribe(() => {
      if (!this.headlessResultList.state.isLoading) {
        this.cdr.detectChanges();
      }
    });

    // this.setDefaultFacet();

      // })
    // })
    
    
    // // console.log("userId", CONFIG.TEMP.USERID);
  }

  async getOneDriveDocsFromServer(){
    var requestPayload = {
      "userId": this.userId,
      "source": "VVMC"
    }
    var vvmcResponse: any = await (await this.onedrive.getDownloadedDocumentsFromOneDrive(requestPayload)).toPromise();
    // console.log("response of docs", vvmcResponse);
    requestPayload.source = "VVQD";
    var vvqdResponse: any = await (await this.onedrive.getDownloadedDocumentsFromOneDrive(requestPayload)).toPromise();
    // console.log("response of vvqd", vvqdResponse);
    var allDocs:any;
    try{
      allDocs = [...vvqdResponse, ...vvmcResponse];
    }catch(err){

    }
    // console.log("All Docs", allDocs);
    var docIds:any;
    try{
      docIds = allDocs.map((document) => {
        return document.DOCUMENT_ID;
      });
    }catch(err){

    }
    // console.log("docIds", docIds);
    return this.oneDriveDocs = docIds;
  }




  // checkCollectionStatus (result) {
  //   try{

  //     // // console.log("this.result===>"+JSON.stringify(result));
  //   // // console.log("this.allCollections===>"+JSON.stringify(this.collectionslist));
  //   for(let collection of this.collectionslist){
  //     if(result.raw['source'] == "GMSA News"){
  //       for(let item of collection.items){
  //         if ((item.docId == result.raw['spouniqueid'])) {
  //           return true
  //         }
  //       }
  //     }else{
  //       for(let item of collection.items){
  //         if ((parseInt(item.docId) == parseInt(result.raw['contentsourceid']))) {
  //           return true
  //         }
  //       }
  //     }
  //   }

  //   // for (let doc of this.collectionslist.collectionlist) {
  //   //   if (parseInt(doc) == parseInt(result.raw['contentsourceid'])) {
  //   //     return true
  //   //   }
  //   // }
  //   return false;

  //   }catch(err){
  //     return false;
  //   }
    
  // }




  checkCollectionStatus (result) {
    var hasCollection = false;
    var docPartOfHowManyCollections = 0
    var res = {
      hasCollection:false,
      docPartOfHowManyCollections:0
    }
    try{

      // // console.log("this.result===>"+JSON.stringify(result));
    // // console.log("this.allCollections===>"+JSON.stringify(this.collectionslist));
    for(let collection of this.collectionslist){
      if(result.raw['source'] == "GMSA News" || result.raw['source'] == "PIE" || result.raw['source'] == "Strategic Partnerships"){
        for(let item of collection.items){
          if ((item.docId == result.raw['spouniqueid'])) {
            hasCollection = true;
            docPartOfHowManyCollections = docPartOfHowManyCollections+1;
            
            // return hasCollection;
            // return true;
          }
        }
      }else{
        for(let item of collection.items){
          // if ((parseInt(item.docId) == parseInt(result.raw['contentsourceid']))) {
          if ((item.docId == result.raw['contentsourceid'])) {
            hasCollection = true;
            docPartOfHowManyCollections = docPartOfHowManyCollections+1;
            // return hasCollection;
            // return true;
          }
        }
      }
    }

    // for (let doc of this.collectionslist.collectionlist) {
    //   if (parseInt(doc) == parseInt(result.raw['contentsourceid'])) {
    //     return true
    //   }
    // }

    res.hasCollection = hasCollection;
    res.docPartOfHowManyCollections = docPartOfHowManyCollections;
    return res;

    }catch(err){
      return false;
    }
    
  }

  async getCollectionsDocsFromServer(){
    return new Promise(resolve =>{

      var data = {
        "collectionId": "",
        "userId": this.userId,
        "tab": ""
    }
    this.collectionService.getAllCollections(data).subscribe((collections)=>{
    this.collectionslist = collections.collectionlist;
    // console.log("collectionslist", this.collectionslist);
    resolve(collections.collectionlist);
    //  this.collectionslist= [];
   })

    });
    



  


    // var requestPayload = {
    //   "userId": CONFIG.TEMP.USERID,
    //   "source": "VVMC"
    // }
    // var vvmcResponse: any = await (await this.onedrive.getDownloadedDocumentsFromOneDrive(requestPayload)).toPromise();
    // // console.log("response of docs", vvmcResponse);
    // requestPayload.source = "VVQD";
    // var vvqdResponse: any = await (await this.onedrive.getDownloadedDocumentsFromOneDrive(requestPayload)).toPromise();
    // // console.log("response of vvqd", vvqdResponse);
    // var allDocs = [...vvqdResponse, ...vvmcResponse]
    // // console.log("All Docs", allDocs);
    // var docIds = allDocs.map((document) => {
    //   return document.DOCUMENT_ID;
    // });
    // // console.log("docIds", docIds);
    // return this.oneDriveDocs = docIds;
  }

  

  public queryButtonCallback = async (result: Result) => {
       
    const engine = await this.engineService.getEngibeObj();
    const { updateQuery } = loadQueryActions(engine);
    const { executeSearch } = loadSearchActions(engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    engine.dispatch(updateQuery({ q: result.title }));
    engine.dispatch(executeSearch(logSearchboxSubmit()));

    // const action = loadAdvancedSearchQueryActions(this.engine).updateAdvancedSearchQueries({
    //     //   aq: '@author="BBC News"',
    //     // });
  };

  openViewPage(result:Result,i:any) {
    const documentPosition = i + 1; // Calculate position based on index (adding 1 because index starts from 0)
    const payload = {
      language: 'en',
      userAgent: navigator.userAgent,
      collectionName: 'default',
      documentAuthor: result.raw['sysauthor'] || 'unknown',
      documentPosition: documentPosition, // Adjust as per your application's logic
      documentTitle: result.title,
      documentUri: result.uri,
      documentUriHash: result.raw['sysurihash'] || '',
      documentUrl: result.clickUri,
      rankingModifier: result.rankingModifier || 'Reveal ART',
      sourceName: result.raw?.source,
      queryPipeline: 'AdvancedSearchDefault', // Adjust as per your application's query pipeline
      originContext: 'Search', // Adjust as per your application's context
      originLevel1: 'AdvanceSearch', // Adjust as per your application's context
      originLevel2: 'default', // Adjust as per your application's context
      originLevel3: 'default', // Adjust as per your application's context
      customData: {
        coveoHeadlessVersion: '2.63.5', // Adjust version based on your actual version
        contentIDKey: 'permanentid',
        contentIDValue: result.raw.permanentid,
        coveoAtomicVersion: '2.65.5' // Adjust version based on your actual version
      },
      facetState: [],
      anonymous: false,
      clientId: this.cookie.get('coveo_visitorId'),
      actionCause: 'documentOpen',
      searchQueryUid: localStorage.getItem('searchID'), // Ensure this is set in your component
    };
    // console.log(payload);
    try {
      // Attempt to call the clickEventsDocumentOpen function with the payload
      this.clickEventsDocumentOpen(payload);
    } catch (error) {
      // Handle any errors that occur during the function call
      console.error('Error occurred during clickEventsDocumentOpen:', error);
      // Optionally, you can add additional error handling logic here
    }   
    try{

      let docId = result.raw['contentsourceid'];
    let docTitle = encodeURIComponent(result.title);
     
    // docId = encodeURIComponent(this.cryptoService.encryptData(docId));
    // // console.log("docId-->"+docId);
    // docTitle = encodeURIComponent(this.cryptoService.encryptData(docTitle));
    // // console.log("docTitle-->"+docTitle);

    // const newWindow = window.open('', '_blank');
    // if (newWindow) {
      let allowPdfDownload;
      try{
        allowPdfDownload = result.raw['vvmcallowpdfdownload'];
      }catch(err){
        allowPdfDownload = "";
      }

      try{
        if(allowPdfDownload==="Approved Email"){
          let makeURI = '/viewpage/'+docId+"/"+docTitle+"/2";
          const url = this.router.createUrlTree([makeURI]).toString();
          // console.log("url-->"+url);
          window.open(url, "_blank");
        }else{
          let makeURI = '/viewpage/'+docId+"/"+docTitle+"/1";
          const url = this.router.createUrlTree([makeURI]).toString();
          // console.log("url-->"+url);
          window.open(url, "_blank");
          }
      }catch(err){
      }
      
      // let makeURI = '/viewpage/'+docId+"/"+docTitle;
      // const url = this.router.createUrlTree([makeURI]).toString();

      // // const url = this.router.serializeUrl(
      // //   this.router.createUrlTree(['/example'])
      // // );

      // // console.log("url-->"+url);
      // // newWindow.location.href = url;
      // window.open(url, "_blank");
    // }

    }catch(err){

    }
  }



  openNISViewPage(result) {
    // console.log("result", result);
   
    // let payload = {
    //   anonymous: false,
    //   clientId: this.cookieService.get('coveo_visitorId'),
    //   "actionCause": "documentOpen",
    //   "documentPosition": 2,
    //   "documentTitle":result.title,
    //   "documentUrl": result.uri,
    //   "language": "en",
    //   "searchQueryUid" : this.searchUID,
    //   "sourceName": result.raw.source,
    //   "customData": {
    //     "contentIDKey": result.raw.permanentid,
    //     "contentIDValue": result.raw['contentsourceid']
    //   }
    // }
    // // console.log(payload);
    // this.clickEventsDocumentOpen(payload);
    try{

      let docId = result.id;
    let docTitle = encodeURIComponent(result.name__v);
     
    // docId = encodeURIComponent(this.cryptoService.encryptData(docId));
    // // console.log("docId-->"+docId);
    // docTitle = encodeURIComponent(this.cryptoService.encryptData(docTitle));
    // // console.log("docTitle-->"+docTitle);

    // const newWindow = window.open('', '_blank');
    // if (newWindow) {
      let makeURI = '/viewpage/'+docId+"/"+docTitle+"/10";
      const url = this.router.createUrlTree([makeURI]).toString();

      // const url = this.router.serializeUrl(
      //   this.router.createUrlTree(['/example'])
      // );

      // console.log("url-->"+url);
      // newWindow.location.href = url;
      window.open(url, "_blank");
    // }

    }catch(err){

    }
  }


  downloadFile(result) {
    try{
      var docId = result.id;
      var userId = this.auth.getUserId();
      var payload = 
      {
        docId : docId,
        userId:userId
      }
      this.viewPageService.downloadFile(payload).subscribe(res=>{
        if(res.statusCode==200){
          window.location.assign(res.data);
        }else{
          // alert("response--->"+JSON.stringify(res));
          this.filedownloaderror = true;
        //   setTimeout(function() {
            
        // }, 5000);
        // this.snackBarFlag = false;
        }
        
      })
    }catch(err){

    }

    function sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    }

    // alert(JSON.stringify(result));
    // var docId = result.id;
    // var fileName = result.name__v;
    // fileName = fileName.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')
    // alert("https://z96trhrgie.execute-api.us-east-1.amazonaws.com/dev/veevafile/"+fileName+"?docId="+docId+"&userId=2");
    // try{
    //   window.location.assign("https://z96trhrgie.execute-api.us-east-1.amazonaws.com/dev/veevafile/"+fileName+"?docId=102&userId=2")
    // }catch(err){

    // }


    // this.httpClient.get("https://z96trhrgie.execute-api.us-east-1.amazonaws.com/dev/veevafile/"+fileName+"?docId="+docId+"&userId=2",{responseType:'blob'}).pipe(
    //   map()
    // )



    
    // window.open("https://z96trhrgie.execute-api.us-east-1.amazonaws.com/dev/veevafile/"+fileName+"?docId=102&userId=2");
    // window.close();
    // this.viewService.downloadFile("params").subscribe(res=>{
    // })
   
  }


  getFileTypeImage(result: Result) {
    try{
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        './../../assets/' + result.raw['filetype'] + '.svg'
      );
    }catch(err){
      return "";
    }
    
  }

  selectedDocs: any = [];
  selectedCount = 0;
  checkedCount = 0;
  selectionFreeze:any= false;
  closeSelectionPopUp(){
    this.selectionFreeze = false;
}
  onSelectChange(id: any, event: any) {
   
    this.ngZone.run(async () => {

        if(localStorage.getItem("isOneDriveInProgress")){
          
          // console.log("isOneDriveInProgress"); 
          let isInSelectedList = false;
            var x:any = localStorage.getItem("selectedDocsDisabilityCheck");
            var checkItems:any[] =  JSON.parse(x);
          
           checkItems.forEach( selectedDoc =>{
             if(selectedDoc.docId == event.target.id){
              isInSelectedList = true;
              event.target.checked = true;
             }
           })

           if(isInSelectedList){
              //  alert("sorry in the list");
              this.selectionFreeze = true;
                return;
           }

        }
     // // console.log("id check",id);
      this.showAllSelectedFlag = true;
       if(this.dom.getElementById("showSelected")){
      this.dom.getElementById("showSelected").style.display=  "block";
       }
      // if (this.selectedDocs.length != 10 ) {
       // // console.log(this.selectedDocs.length);
        // if (event.target.checked) {
        //   this.selectedCount++;
        // } else {
        //   this.selectedCount--;
        // }
    
     
        
        let docid = id.raw.contentsourceid;
        let documentNumber = id.raw.contentdocumentnumber;
        let permadocid = id.raw.spouniqueid;
        let source = id.raw.source;
        let title = id.title;
       // // console.log(" before this.checkedCount", this.checkedCount);
       // // console.log("event.target.checked", event.target.checked);
        // if(event.target.checked == false){
        //   this.checkedCount = this.checkedCount + 1;
        // }
       
  
        //// console.log(" after this.checkedCount", this.checkedCount);
        //// console.log("event.target.checked", event.target.checked);

        if (this.selectedDocs.length > 9 && event.target.checked) {
          //// console.log("event obj", event.target);
          // this.selectedCount--;
          //this.checkedCount = 10;
           //this.checkedCount--;
          // event.target.checked = false;
          this.maxSelectionPopUp = true;
        
          // alert("anbc");
         // // console.log("this.maxSelectionPopUp", this.maxSelectionPopUp);
          event.target.checked = false;
          return;
         // event.target.checked = false;
          //event.target.checked = true;
         // return;
         
          // this.openPopup();
        }

        if (event.target.checked && this.selectedDocs.length < 10) {
          if (source == "GMSA News" || source == "PIE" || source == "Strategic Partnerships") {
            this.selectedDocs.push({
              "docId": permadocid,
              "source": source,
              "name":title,
              "documentNumber" : documentNumber
            });
          }
          else if(source == "Veeva Vault MedComms") {
            this.selectedDocs.push({
              "docId": docid,
              "source": "VVMC",
              "name":title,
              "documentNumber" : documentNumber

            });
          }
          else if(source == "Value Evidence Environmental Scan") {
            this.selectedDocs.push({
              "docId": docid,
              "source": "VEES",
              "name":title,
              "documentNumber" : docid

            });
          }
          else if(source == "Idea Exchange") {
            this.selectedDocs.push({
              "docId": docid,
              "source": "Idea Exchange",
              "name":title,
              "documentNumber" : docid

            });
          }
          else {
            this.selectedDocs.push({
              "docId": docid,
              "source": "VVQD",
              "name":title,
              "documentNumber" : documentNumber

            });
          }


          // console.log("selectedDocs", this.selectedDocs);
        }
        else {

           // // console.log("inside else");
          this.checkedCount = this.checkedCount - 1;
          // event.target.checked = false;
          if (source == "GMSA News" || source == "PIE" || source == "Strategic Partnerships") {
            this.selectedDocs = this.selectedDocs.filter((el) => {
              return el.docId !== permadocid;
            });
           // // console.log(this.selectedDocs);

          }
          else {
            
            this.selectedDocs = this.selectedDocs.filter((el) => {
              return el.docId !== docid;
            });
           // // console.log(this.selectedDocs);
          }
        
        }
      // }
      // else {
      //   this.maxSelectionPopUp = true;
      //   event.target.checked = false;
      //   return;
      // }
     // // console.log("selectedDocs length", this.selectedDocs.length);
      // localStorage.setItem('selectedDocuments', JSON.stringify(this.selectedDocs));
        //this.cookieService.set('selectedDocuments', JSON.stringify(this.selectedDocs));
       localStorage.setItem('selectedDocuments', JSON.stringify(this.selectedDocs));
      

      // if(this.selectedDocs.length > 1){
      //    // this.selectedCount--;
      //   //  this.maxSelectionPopUp = true;
      //    event.target.checked = false;
      //  return;
      // }

      if(this.selectedDocs.length == 0){
        // console.log("this.isUserInShowSelected", this.isUserInShowSelected);
         
        this.showAllSelectedFlag = false;
        this.selectedDocs = [];
        if(this.isUserInShowSelected){
          this.clearshowAllSelected();
        }
       
       // this.backToResults = true;
        //this.clearAll();
        //this.allDeselected();
      }

     })

  }
  closeMaxPopUp(){
    this.maxSelectionPopUp = false;
  }

  allDeselected(){
    this.selectedDocs = [];
    this.showAllSelectedFlag = false;
  }


  maxSelectionPopUp : boolean = false;

  isSelected(documentId:any) {
    // let id:any = localStorage.getItem('selectedDocuments');
    try{
      //let id:any = this.cookieService.get('selectedDocuments');
      let id:any = localStorage.getItem('selectedDocuments');
      if(id){
        //// console.log("documentId DOcs", documentId);
        //// console.log("selected DOcs", this.selectedDocs);
        return id.includes(documentId);
      }else{
        return false;
      }
    }catch(err){
      return false;;
    }
    

  }

  formatDate(inputDate: string): string {
    if (!inputDate || inputDate.trim().toLowerCase() === 'n/a') {
        return 'N/A'; // Return 'N/A' if inputDate is undefined or 'N/A'
    }

    // Split the input date into parts
    let parts = inputDate.trim().split(' ');

    if (parts.length !== 3) {
        return 'N/A'; // Return 'N/A' if date format is not as expected (e.g., not "August 21, 2018")
    }

    let month = parts[0]; // "August"
    let day = parts[1].slice(0, -1); // "21," - Remove the comma
    let year = parts[2]; // "2018"

    // Construct the formatted date string
    let formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
}





  docID: any;
  source: string = '';

  //   addToOneDrive(result){
  //     this.ngZone.run(() => {
  //     // console.log("result",result);
  //     this.docID = result.raw.contentsourceid;
  //     this.source = result.raw.source;
  //     this.feature = "addToOneDrive";
  //     this.showPopup = true;
  //     });
  //   }

  closePopup() {
    this.showPopup = false;
    this.showPopupCollection = false;
  }

  download() {
    this.onedrive.download();
    // alert("CALLED from parent");
  }
 
  addtoonedriveMultiple : boolean= false;

//   async addToOneDrive(r: any, id) {
   

// }

 async removeMultipleDocsFromOneDrive(){
  this.closeflagpopup = false;
  this.feature = "removeMultipleDocsFromOneDrivefeature";
  this.showPopup = true;
  this.title=CONFIG.ONEDRIVE.REMOVE_MULTI_DOCUMENT_TITLE
  this.body= CONFIG.ONEDRIVE.REMOVE_MULTI_DOCUMENT_BODY;

  
}

  async addToOneDrive(r:any,id:any){
    // start
 if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
  var resp = await this.auth.getMaintenanceStatus({
    "checkForMaintainence":true
  });
  // console.log("resp", resp);
       
       if(resp == "true"){
        sessionStorage.setItem("isInMaintenance", "true");
        this.router.navigateByUrl("/maintenance");
        return;
       }
     }
      
// end
    this.closeflagpopup = false;
    // Checking is the Document Selected or NOT based on the ID
    let checked = this.selectedDocs.filter(x => x.docId == id);
    if(checked.length == 1)
    {
       this.addtoonedriveMultiple = true;
    }
    if(checked.length == 0)
    {
      this.addtoonedriveMultiple = false;
    }
   // // console.log("Checking if Document Selected", this.addtoonedriveMultiple);

   // Checking is the Document Selected or NOT   


    // console.log("CALLED");
    this.ngZone.run(async () => {

      var source = "";
      if (r.raw.source == "Veeva Vault QualityDocs Source-1" || r.raw.source == "Veeva Vault QualityDocs Source-2" || r.raw.source == "Veeva Vault QualityDocs") {
        source = "VVQD";
      }
      if (r.raw.source == "Veeva Vault MedComms") {
        source = "VVMC";
      }
      CONFIG.TEMP.DOCID = r.raw['contentsourceid'];
      CONFIG.TEMP.SOURCE = source;

      // console.log("CONFIG.TEMP.DOCID", CONFIG.TEMP.DOCID);
      // console.log("CONFIG.TEMP.SOURCE", CONFIG.TEMP.SOURCE);
      var requestPayload = {
        "isRegistered": "",
        "id": this.userId
      }

      var checkIfUserAlreadyRegisteredResponse: any = await (await this.onedrive.checkIfUserAlreadyRegistered(requestPayload)).toPromise();
      if (checkIfUserAlreadyRegisteredResponse.isRegistered == false) {

        localStorage.setItem('docId', r.raw['contentsourceid']);
        localStorage.setItem('source', r.raw.source);
        localStorage.setItem("userId", this.userId);
        window.open(CONFIG.ONEDRIVE.REDIRECT_URL, "_blank");
        return;
      }

        
    // FOR SINGLE DOCUMENT SELECTED
     if(!this.addtoonedriveMultiple)
     {
      this.feature = "addToOneDrive";
      this.showPopup = true;
      this.res = r;
      this.title = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_TITLE;
      this.body = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_BODY;
     }

    // FOR SINGLE DOCUMENT SELECTED

    // FOR MULTIPLE DOCUMENT SELECTED
    if(this.addtoonedriveMultiple)
    {
      this.feature = "addToOneDrive";
      this.showPopup = true;
      this.res = this.selectedDocs;
      this.title = CONFIG.ONEDRIVE.DOWNLOAD_MULTIPLE_DOCUMENT_TITLE;
      this.body = CONFIG.ONEDRIVE.DOWNLOAD_MULTIPLE_DOCUMENT_BODY;
    }

    // FOR MULTIPLE DOCUMENT SELECTED


    })


  }

  async removeFromOneDrive(abc){
    //start
   
if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
  var resp = await this.auth.getMaintenanceStatus({
    "checkForMaintainence":true
  });
  // console.log("resp", resp);
        
       if(resp == "true"){
        sessionStorage.setItem("isInMaintenance", "true");
        this.router.navigateByUrl("/maintenance");
        return;
       }
      }
      
// send
    this.closeflagpopup = false;
    this.ngZone.run(() =>{
      this.feature = "removeFromOneDrive";
      this.showPopup = true;
      this.res = abc;
      this.title=CONFIG.ONEDRIVE.REMOVE_SINGLE_DOCUMENT_TITLE;
      this.body= CONFIG.ONEDRIVE.REMOVE_SINGLE_DOCUMENT_BODY;
      // console.log("this.showPopup", this.showPopup);
    })
    
  }

  async removeMultipleFromOneDrive(abc){
  // start 

  if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    
           // console.log("typeof resp", typeof resp);
         if(resp == "true"){
          sessionStorage.setItem("isInMaintenance", "true");
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
   // // console.log("removeMultipleFromOneDrive -> result list component");
    //return;
    this.closeflagpopup = false;
    this.ngZone.run(() =>{
      this.feature = "removeMultipleFromOneDrive";
      this.showPopup = true;
      this.res = abc;
      this.title=CONFIG.ONEDRIVE.REMOVE_MULTI_DOCUMENT_TITLE;
      this.body= CONFIG.ONEDRIVE.REMOVE_MULTI_DOCUMENT_BODY;
      // console.log("this.showPopup", this.showPopup);
    })
    
  }

  getDateFormat(dateString: any) {
    // // console.log("dateString-->"+dateString);
    return new Date(dateString);
  }

  checkOneDriveStatus(){
    alert("CALLED");
  }

  viewCollections(){
    this.ngZone.run(() =>{
    this.feature="collections";
    this.showPopup=true;
    })
    if(this.selectedDocs.length>0){
      // multi select colleciton add

      // var data = `{"name":"undefined","id":"426","items":[{"docId":\"`+id+`\","docNumber":\"`+docNumber+`\"}],"userId":"`+useridDecrypted+`","operation":"UPDATE_DOC", "feature":"`+tab+`"}`;
      var data = {
            "collectionId": "",
            "userId": this.userId,
            "tab": "VVMC"
        }
      
      // this.collectionService.addDocToCollection(data).subscribe((resp)=>{
      //   alert("resp--->"+JSON.stringify(resp));
      // })


    }else{
      // single select colleciton add

    }
  }
  insightsExpanded:boolean=false;

  toggleInsights() {
    this.insightsExpanded = !this.insightsExpanded;
}



  shareItem(result:Result){

    

    let userFullName = this.cookieService.get("fn");
    //userFullName = userFullName? this.cryptoService.decryptData(userFullName):"";
    var source = result.raw['source'];
    var docID:any = "";
    var linkUrlPart = ""
    // console.log("source--->"+source+"----docID-->"+docID);
    // console.log("source--->"+JSON.stringify(result));
    if(source=="Veeva Vault MedComms" || source=="Veeva Vault QualityDocs Source-1" || source=="Veeva Vault QualityDocs Source-2" || source=="Veeva Vault QualityDocs" || source === "Veeva Vault PromoMats" || source === "Model Repository"){
      docID = result.raw['contentsourceid'];
      linkUrlPart = '%40contentsourceid%3D';
    }else if(source=="GMSA News" || source=="PIE" || source=="Strategic Partnerships"){
      docID = result.raw['spouniqueid'];
      linkUrlPart = '%40spouniqueid%3D';
    }
    else if(source=="Idea Exchange"){
      docID = result.raw['contentsourceid'];
      // linkUrlPart = '%40spouniqueid%3D';
    }
    else if(source=="Value Evidence Environmental Scan"){
      docID = result.raw['contentsourceid'];
      // linkUrlPart = '%40spouniqueid%3D';
    }
    else if(source=="Idea Exchange"){
      docID = result.raw['contentsourceid'];
      // linkUrlPart = '%40spouniqueid%3D';
    }

    // console.log("source--->"+source+"----docID-->"+docID);

    if(!docID){
      alert("Document/News link ID is not available.");
      return;
    }

     //var shareLink = "https://gmsasearch-sit.merck.com"

    // var shareLink = "https://gmsasearch-dev.merck.com/"
    var shareLink = CONFIG.CONFIRMATION.REDIRECT_URL+"/";
    var disclaimer_label = "";
    var disclaimer_txt = "";
    var email ='';
    var subject = 'Content Search Navigator: Asset share';
    var emailBody = userFullName +` has invited you to access and explore the information for the  document(s): `;	
    
        // var fullName = value.split(",")[2];	
        // var trimmedName =  fullName.length>17 ? fullName.substring(0,17)+"..." : fullName;	
        // emailBody += "\n"+sNo+"."+" "+trimmedName;	
    var link = linkUrlPart+docID;	
    // emailBody +="\n";
    console.log("this.selectedDocs.length", this.selectedDocs.length);
    if(this.selectedDocs.length>1){
      emailBody +="\n";
      for(let doc of this.selectedDocs){
        console.log("selected docs", this.selectedDocs);

        emailBody +="\n";
        var trimmedString = "";
        if(doc.name.length > 25){
          trimmedString = `${doc.name}`.substring(0, 25)+"...";
        }
        else{
          trimmedString = doc.name;
        }

      
        emailBody += trimmedString;	
        // emailBody +="\n";

        if(doc.source=="VVMC" || source=="Veeva Vault QualityDocs Source-1" || doc.source=="Veeva Vault QualityDocs Source-2" || doc.source=="Veeva Vault QualityDocs"){
          docID = doc.docId;
          linkUrlPart = '%40contentsourceid%3D';
          link = linkUrlPart+docID;	
        }else if(doc.source=="GMSA News" || source=="PIE"){
          docID = doc.docId;
          linkUrlPart = '%40spouniqueid%3D';
          link = linkUrlPart+docID;
        }
        else if(doc.source=="Value Evidence Environmental Scan"){
          console.log("docid",docID);
          docID = doc.docId;
          link = docID;
        }
        else if(doc.source=="Idea Exchange"){
          console.log("docid",docID);
          docID = doc.docId;
          link = docID;
        }
        else if(doc.source == "Idea Exchange"){
          docID = doc.docId;
          link = docID;
        }
        emailBody += "\n"+"Link: " + (shareLink +'#q='+link);
        emailBody +="\n";
      }
    }else{
      emailBody += `${result.title}`;	
      emailBody += "\n"+"Link: " + (shareLink +'#q='+link);	
    }
    // sNo = sNo+1;	
    emailBody +="\n";	
    emailBody = encodeURIComponent(emailBody);	
    emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
    emailBody += this.disclaimer_txt;
    document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;	
    
  }

  disclaimer_label = "About Content Search Navigator:";
  disclaimer_txt = "The Content Search Navigator is an advanced search engine tool that provides GMSA colleagues with a simplified approach for efficiently and effectively searching across multiple repositories for scientific and related content.";

  async addTocollections(result:any,action:any){
    if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
      var resp = await this.auth.getMaintenanceStatus({
        "checkForMaintainence":true
      });
      
           if(resp == "true"){
            sessionStorage.setItem("isInMaintenance", "true");
            this.router.navigateByUrl("/maintenance");
            return;
           }
          }
    this.collectionComp.addTocollections(result,action);
  }

  wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }

  @Input() showAllSelectedFlag = false;
  selectedDocsTmp:any;
  isUserInShowSelected:boolean = false;
  showAllSelected(event:any){
this.isUserInShowSelected = true;
           if(this.dom.getElementById("bannerCollections")){
            this.dom.getElementById("bannerCollections").style.display= "none";
           }
    this.backToResults = true;
    var selectedDoc = this.selectedDocs;
    this.selectedDocsTmp = this.selectedDocs;
    this.clearAll();
    this.wait(2000);

    let engine = this.engineService.getEngibeObj();
    var selectedDocsArra:any = [];
    for(let doc of selectedDoc){
      selectedDocsArra.push(doc.docId);
    }
    let dataforQuery = selectedDocsArra.join(",");
    // console.log('dataforQuery',dataforQuery);
    // const engine = this.engine.getEngibeObj()
    // const { updateQuery } = loadQueryActions(engine)
    const { executeSearch } = loadSearchActions(engine)
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine)
   
    const action = loadAdvancedSearchQueryActions(
      engine
    ).updateAdvancedSearchQueries({
      aq: `@contentsourceid==(${dataforQuery}) OR @spouniqueid==(${dataforQuery})`
    })
    engine.dispatch(action)
    engine.dispatch(executeSearch(logSearchboxSubmit()))
    // console.log(localStorage.getItem('selectedDocuments'));
  }

  clearshowAllSelected(){
       // console.log("clearshowAllSelected called");
    this.backToResults = false;
    this.isUserInShowSelected = false;
    if(this.dom.getElementById("bannerCollections")){
      this.dom.getElementById("bannerCollections").style.display= "none";
     }
    this.clearAll();   
  }

  clearshowAllSelectedBtn(){
    
    this.showAllSelectedFlag = false;
    // this.cookieService.set('selectedDocuments',"");
    this.checkedCount=0;this.selectedDocs = [];
    localStorage.setItem("selectedDocuments","");
    // this.clearAll();
  }

  copyLinkFlag : boolean = false;
  copyLinkName : any;
  // shareLink:String = "https://gmsasearch-dev.merck.com/"
  shareLink:String = CONFIG.CONFIRMATION.REDIRECT_URL+"/";
  //shareLink:String = "https://gmsasearch-sit.merck.com/"

  copyText : any;
  copylink(result){

    this.ngZone.run(async () => {
      var source= "";
     // // console.log(result);
      var name = result.title;
     // // console.log("name", name);
      if(name.length < 40){
        this.copyLinkName = name.slice(0,40)
      }
      else 
      {
        this.copyLinkName = name.slice(0,40)
      }
      this.copyLinkFlag = true;
      if(result.raw.source == "VVMC" || result.raw.source == "Veeva Vault MedComms"  || result.raw.source == "Veeva Vault PromoMats" || result.raw.source == "Model Repository")
      {
        source = encodeURI(result.raw.source);
       // this.copyText = `${this.shareLink}#q=%40contentsourceid%3D${result.raw.contentsourceid}&t=VVMC&sort=relevancy`
       this.copyText = `${this.shareLink}#q=%40contentsourceid%3D${result.raw.contentsourceid}&f-source=${source}`
    
      }
    
      if(result.raw.source == "VVQD" || result.raw.source == "Veeva Vault QualityDocs Source-1" || result.raw.source == "Veeva Vault QualityDocs Source-2" || result.raw.source == "Veeva Vault QualityDocs"){
        source = encodeURI(result.raw.source);
       // this.copyText = `${this.shareLink}#q=%40contentsourceid%3D${result.raw.contentsourceid}&t=VVQD&sort=relevancy`
       this.copyText = `${this.shareLink}#q=%40contentsourceid%3D${result.raw.contentsourceid}&f-source=${source}`
    
      }
      if(result.raw.source == "GMSA News" || result.raw.source == "PIE" || result.raw.source == "Strategic Partnerships"){
        source = encodeURI(result.raw.source);
       // this.copyText = `${this.shareLink}#q=%40spouniqueid%3D${result.raw.spouniqueid}`
       this.copyText = `${this.shareLink}#q=%40spouniqueid%3D${result.raw.spouniqueid}&f-source=${source}`
    
      }
      if(result.raw.source == "Idea Exchange"){
        source = encodeURI(result.raw.source);
       // this.copyText = `${this.shareLink}#q=%40spouniqueid%3D${result.raw.spouniqueid}`
       this.copyText = `${this.shareLink}#q=${result.raw.contentsourceid}&f-source=${source}`
    
      }
      if(result.raw.source == "Value Evidence Environmental Scan"){
        source = encodeURI(result.raw.contentsourceid);
       // this.copyText = `${this.shareLink}#q=%40spouniqueid%3D${result.raw.spouniqueid}`
       this.copyText = `${this.shareLink}#q=${result.raw.contentsourceid}`
    
      }
      if(this.copyText && this.copyText!==undefined && this.copyText!==null && this.copyText!==""){
        this.copyLinktoNavigator(this.copyText)
      }else{
        alert("Copy Link is not enabled!");
      }
      
    });

  }

  sleep (ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }


  snackBarFlag : boolean = false;
  async copyLinktoNavigator(url){
    // this.CopyFlag = true;
    // console.log("idtab",url);
    navigator.clipboard.writeText(url);
    // console.log("copied Url", url);
    this.snackBarFlag = true;
    await this.sleep(5000);
    this.snackBarFlag = false;


  }

  clearAll(){
    // let item:any = [];
    // localStorage.setItem('selectedDocuments',item) ;
    // this.selectedDocs = [];
    let engine = this.engineService.getEngibeObj();
    var facetState = engine.state.facetSet;
    // console.log("facetState--->"+facetState);

    for (const k in facetState) {
      // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
        engine
    ).deselectAllFacetValues(facetState[k].request.facetId);
    engine.dispatch(toggleSelectFacetValueaction1);
    }


    const action = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({aq: '',});
    engine.dispatch(action);

    const actionDf = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({dq: '',});
    engine.dispatch(actionDf);

    const { updateQuery } = loadQueryActions(engine);
    const { executeSearch } = loadSearchActions(engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    engine.dispatch(updateQuery({q:''}));
  
    engine.dispatch(executeSearch(logSearchboxSubmit()));
    engine.executeFirstSearch();

  }


  // custom events 

  customEventsOpenInSource(result:any)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click', Title :result['Title'],'Source' :result['raw']['source']},'Open-in-Source');

  }

  customEventCopyLink(result:any)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click', Title :result['Title'],'Source' :result['raw']['source']},'Copy Link');

  }

  customEventsShareItem(result:any){

    // console.log("inside Share")

    // console.log("selected DOdcs", this.selectedDocs.length);

    if(this.selectedDocs.length > 1)

    {

      this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click', Details : this.selectedDocs},'Share-Document(s)/Link(s)-multi');

    }

    else

    {

      this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click', Title : result['Title']},'Share-Document(s)/Link(s)');

 

    }

 

  }

  customEventAddtoCollections(result:any,value)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Add Collection', Title :result['Title'],'Source' :result['raw']['source']},'MyCollections-add');

  }

  customEventRemovetoCollections(result:any,value)
  {
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Remove Collection', Title :result['Title'],'Source' :result['raw']['source']},'MyCollections-remove');

  }

  veesNoSourceFlag : boolean = false;

  openLink(result: any,i :any): void {
    this.ngZone.run(async () => {
      if (result.raw.source === "Value Evidence Environmental Scan") {
        const url = result.raw.vees_source;
        const documentPosition = i + 1; // Calculate position based on index (adding 1 because index starts from 0)
    const payload = {
      language: 'en',
      userAgent: navigator.userAgent,
      collectionName: 'default',
      documentAuthor: result.raw.sysauthor || 'unknown',
      documentPosition: documentPosition, // Adjust as per your application's logic
      documentTitle: result.title,
      documentUri: result.uri,
      documentUriHash: result.raw.sysurihash || '',
      documentUrl: result.clickUri,
      rankingModifier: result.rankingModifier || 'Reveal ART',
      sourceName: result.raw?.source,
      queryPipeline: 'AdvancedSearchDefault', // Adjust as per your application's query pipeline
      originContext: 'Search', // Adjust as per your application's context
      originLevel1: 'AdvanceSearch', // Adjust as per your application's context
      originLevel2: 'default', // Adjust as per your application's context
      originLevel3: 'default', // Adjust as per your application's context
      customData: {
        coveoHeadlessVersion: '2.63.5', // Adjust version based on your actual version
        contentIDKey: 'permanentid',
        contentIDValue: result.raw.permanentid,
        coveoAtomicVersion: '2.65.5' // Adjust version based on your actual version
      },
      facetState: [],
      anonymous: false,
      clientId: this.cookie.get('coveo_visitorId'),
      actionCause: 'documentOpen',
      searchQueryUid: localStorage.getItem('searchID'), // Ensure this is set in your component
    };
    // console.log(payload);
    try {
      // Attempt to call the clickEventsDocumentOpen function with the payload
      this.clickEventsDocumentOpen(payload);
    } catch (error) {
      // Handle any errors that occur during the function call
      console.error('Error occurred during clickEventsDocumentOpen:', error);
      // Optionally, you can add additional error handling logic here
    }  
        if (url && (url.startsWith("http://") || url.startsWith("https://"))) {
            // Check if `url` is not empty and starts with either "http://" or "https://"
            try {
                window.open(url, '_blank');
                return;
            } catch (error) {
                console.error('Failed to open URL:', error);
                // Handle error, perhaps show a message to the user
            }
        } else {
            // Handle case where URL is empty, undefined, or not valid
            this.veesNoSourceFlag = true; // Set flag indicating no valid source URL
            return;
            console.warn('Invalid URL:', url);
            // Optionally, provide feedback to the user that the URL is invalid
        }
    }
    const documentPosition = i + 1; // Calculate position based on index (adding 1 because index starts from 0)
    const payload = {
      language: 'en',
      userAgent: navigator.userAgent,
      collectionName: 'default',
      documentAuthor: result.raw.sysauthor || 'unknown',
      documentPosition: documentPosition, // Adjust as per your application's logic
      documentTitle: result.title,
      documentUri: result.uri,
      documentUriHash: result.raw.sysurihash || '',
      documentUrl: result.clickUri,
      rankingModifier: result.rankingModifier || 'Reveal ART',
      sourceName: result.raw?.source,
      queryPipeline: 'AdvancedSearchDefault', // Adjust as per your application's query pipeline
      originContext: 'Search', // Adjust as per your application's context
      originLevel1: 'AdvanceSearch', // Adjust as per your application's context
      originLevel2: 'default', // Adjust as per your application's context
      originLevel3: 'default', // Adjust as per your application's context
      customData: {
        coveoHeadlessVersion: '2.63.5', // Adjust version based on your actual version
        contentIDKey: 'permanentid',
        contentIDValue: result.raw.permanentid,
        coveoAtomicVersion: '2.65.5' // Adjust version based on your actual version
      },
      facetState: [],
      anonymous: false,
      clientId: this.cookie.get('coveo_visitorId'),
      actionCause: 'documentOpen',
      searchQueryUid: localStorage.getItem('searchID'), // Ensure this is set in your component
    };
    // console.log(payload);
    try {
      // Attempt to call the clickEventsDocumentOpen function with the payload
      this.clickEventsDocumentOpen(payload);
    } catch (error) {
      // Handle any errors that occur during the function call
      console.error('Error occurred during clickEventsDocumentOpen:', error);
      // Optionally, you can add additional error handling logic here
    }    
    window.open(result.clickUri, '_blank');
  })
    // You can also use Angular Router to navigate if your app has routing configured.
    // For example: this.router.navigate(['/your-route'], { queryParams: { link: url } });
  }

  clickEventsDocumentOpen(result:any)
  {
    this.eventLoggingService.logClickvent(result);

  }

  convertStringToJson(jsonString:string){
    const jsonObject = JSON.parse(jsonString);
    // // console.log("jsonObject-->"+JSON.stringify(jsonObject));
    return jsonObject;
  }

  veesBlankSourceFlag : boolean = false;

  openLinkURL(result: any): void {
    if (result.raw.source === "Value Evidence Environmental Scan") {
      const url = result.raw.vees_source;
  
      if (url && (url.startsWith("http://") || url.startsWith("https://"))) {
          // Check if `url` is not empty and starts with either "http://" or "https://"
          try {
              window.open(url, '_blank');
              return;
          } catch (error) {
              console.error('Failed to open URL:', error);
              // Handle error, perhaps show a message to the user
          }
      } else {
          // Handle case where URL is empty, undefined, or not valid
          this.veesNoSourceFlag = true; // Set flag indicating no valid source URL
          return;
          console.warn('Invalid URL:', url);
          // Optionally, provide feedback to the user that the URL is invalid
      }
  }
}



isValidURL(url: string): boolean {
  const pattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
  return pattern.test(url);
}

  nisStepFill(elementId){
    // // console.log("elementId-->"+elementId);
    let myDiv = <HTMLElement>document.getElementById(elementId);
    myDiv.classList.add('completed');
  }

  // finalDateDisclaimer = false;
  finalDateFill(elementId, val){
    let myDiv = <HTMLElement>document.getElementById(elementId);
    // // console.log("FINAL DATE-->"+val);
    myDiv.innerText = val;
  }

  finalDateDisclaimerFill(elementId){
    let myDiv = <HTMLElement>document.getElementById(elementId);
    // // console.log("FINAL DATE-->"+val);
    myDiv.innerText = 'Protocol Completion Date';
  }


  rendomNum = "";
  randomString(length) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    this.rendomNum = result;
    // return result;
  }

  fillEndorsedYear(divId, endorsedYear){
   var div = <HTMLElement>document.getElementById(divId);
   var divTxt = <HTMLElement>document.getElementById(divId+"_txt");
   if(endorsedYear!='undefined'){
      div.innerText = endorsedYear;
      divTxt.innerText = "Endorsed Year";
   }
   
  }


  expandSteps(elementToExpand){
    try{

    var conceptSheetsDiv = <HTMLElement>document.getElementById('details_concept_sheet_'+elementToExpand);
    var studyProtocolDiv = <HTMLElement>document.getElementById('details_study_reports_'+elementToExpand);
    var studyReposrtsDiv = <HTMLElement>document.getElementById('details_study_protocol_'+elementToExpand);
    var summarySlideDeck = <HTMLElement>document.getElementById('details_summary_slide_deck_'+elementToExpand);
    conceptSheetsDiv.setAttribute('open','true');
    studyProtocolDiv.setAttribute('open','true');
    studyReposrtsDiv.setAttribute('open','true');
    try{
      summarySlideDeck.setAttribute('open','true');
    }catch(err){

    }
    

    var collapseBtn = <HTMLElement>document.getElementById('collapse_'+elementToExpand);
    var expandBtn = <HTMLElement>document.getElementById('expand_'+elementToExpand);
    collapseBtn.style.display = "block";
    expandBtn.style.display = "none";

    }catch(err){
      // // console.log(err);
    }
    
  }

  collapseSteps(elementToExpand){
    try{

    var conceptSheetsDiv = <HTMLElement>document.getElementById('details_concept_sheet_'+elementToExpand);
    var studyProtocolDiv = <HTMLElement>document.getElementById('details_study_reports_'+elementToExpand);
    var studyReposrtsDiv = <HTMLElement>document.getElementById('details_study_protocol_'+elementToExpand);
    var summarySlideDeck = <HTMLElement>document.getElementById('details_summary_slide_deck_'+elementToExpand);

    var collapseBtn = <HTMLElement>document.getElementById('collapse_'+elementToExpand);
    var expandBtn = <HTMLElement>document.getElementById('expand_'+elementToExpand);

    conceptSheetsDiv.removeAttribute('open');
    studyProtocolDiv.removeAttribute('open');
    studyReposrtsDiv.removeAttribute('open');
    try{
      summarySlideDeck.removeAttribute('open');
    }catch(err){

    }
    
    collapseBtn.style.display = "none";
    expandBtn.style.display = "block";

    }catch(err){
      // // console.log(err);
    }
    
  }







  getOneCountryInCard(eleId,countryCommaSeparatedList,allCountries){
    // console.log("countryCommaSeparatedList-->"+countryCommaSeparatedList);
    // console.log("allCountries-->"+allCountries);
    if(countryCommaSeparatedList.length>0){
      var countryArray = [];
      countryArray = countryCommaSeparatedList.toString().split(',');
        let myDiv = <HTMLElement>document.getElementById("one-country-"+eleId);
        myDiv.innerText = countryArray[0];
    }
    
  }

  getAllCountryInCard(eleId,countryCommaSeparatedList,allCountries){
    // console.log("countryCommaSeparatedList-->"+countryCommaSeparatedList);
    // console.log("allCountries-->"+allCountries);
    if(countryCommaSeparatedList.length>0){
      var countryArray = [];
      countryArray = countryCommaSeparatedList.toString().split(',');
        let myDiv = <HTMLElement>document.getElementById("all-country-"+eleId);
        myDiv.innerHTML = countryArray.join(", ")
    }
    
  }


  showOneCountry(eleId){
    let myDiv = <HTMLElement>document.getElementById("all-country-dev-"+eleId);
    myDiv.style.display = "none";

    let othermyDiv = <HTMLElement>document.getElementById("one-country-dev-"+eleId);
    othermyDiv.style.display = "inline";
  }


  showAllCountries(eleId){
    let myDiv = <HTMLElement>document.getElementById("one-country-dev-"+eleId);
    myDiv.style.display = "none";


    let othermyDiv = <HTMLElement>document.getElementById("all-country-dev-"+eleId);
    othermyDiv.style.display = "inline";
  }


  showRightArrowInCard(eleId,countries){

    if(countries){
      countries = countries.toString().split(',');
      return countries[1]?true:false;
    }else{
      return false;
    }

  }

  removeWord(sentence: String) {
    try{
      return sentence.replace('Description', '')  ;
    }catch(err){
      return ''
    }
  }

}

