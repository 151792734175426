// old code
// import { Component, EventEmitter, Input, Output } from '@angular/core';
// import { Result } from '@coveo/headless';
// import { OnedriveService } from '../services/onedrive.service';

// @Component({
//   selector: 'app-csn-popup',
//   templateUrl: './csn-popup.component.html',
//   styleUrls: ['./csn-popup.component.css']
// })
// export class CsnPopupComponent {

//   constructor(private onedriveservice : OnedriveService){}

//   @Output() close = new EventEmitter<void>();
//   @Output() downloadToOnedrive = new EventEmitter<void>();
//   @Input() result!:Result;
//   @Input() feature!:string;
//   @Input() docID! : any;
//   @Input() source! : any;


//   addToOneDrive(){
//     alert(this.result.title);
//   }

//   closePopup() {
//     this.close.emit();
//   }

//   download() {
//     this.downloadToOnedrive.emit();
//   }

// }


// new code 


import { Component, EventEmitter, Input, NgZone, Output, ChangeDetectorRef, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { CONFIG } from 'src/environments/environment.development';
import { OnedriveService } from '../services/onedrive.service';
import { Location } from '@angular/common';
import { SharedService } from '../shared.service';
import { CollectionsService } from '../collections.service';
import { Result } from '@coveo/headless'
import { AuthService } from '../auth.service';



@Component({
  selector: 'app-csn-popup',
  templateUrl: './csn-popup.component.html',
  styleUrls: ['./csn-popup.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsnPopupComponent{

  @Output() close = new EventEmitter<void>();

  @Input() result!: Result;
  @Input() feature!: string;
  @Input() title!: string;
  @Input() body!: string;
  @Input() buttons!: string[];
  @Input() document!: any;
  @Input() onedrive_icon!: any;
  collectionViewFlag : boolean = false;
  collectionAddFlag  : boolean =true;
  @Input() closeBtn!: any;
  @Input() collectionslist:any;
  @Input() selectedDocs!:any;
  userId:any;
  progress: boolean = false;
  progressMessage: string = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_INPROGRESS;
  oneDriveInfoMessage: string = CONFIG.ONEDRIVE.INFO_MESSAGE;
  constructor(private oneDriveOperationsService: OnedriveService, 
    private collectionsService:CollectionsService,
    private location: Location, private ngZone: NgZone,private sharedService: SharedService, private auth: AuthService) { }

    @Output() emptyCollectionPopupEmit : EventEmitter<any> = new EventEmitter();
    @Output() emptyDrivePopupEmit : EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    //// console.log("feature", this.feature);
    //// console.log("document", this.document);
    this.userId = this.auth.getUserId();
    if (this.feature == "checkboxLimitSelection") {
      this.maximumFlag = true;
    }
    // this.loadAllCollections(this.document);
    this.renameFlag = false;
    if(this.feature == "addCollections")
    {
      this.collectionAddFlag = true;
    }
    if(this.feature == "collections")
    {
      this.collectionViewFlag = true;
    }
  }
  // dynamicTemplate = `
  //   <div>
  //     <h1>[TITLE]</h1>
  //     <h2>[SUPERCHRIS]</h2>
  //     <p>[CONTENT]</p>
  //   </div>
  // `;

  // getDynamicTemplate() {
  //   let dynamicTemplateParsed = this.dynamicTemplate;
  //   dynamicTemplateParsed = dynamicTemplateParsed.replace('[TITLE]', 'Some Title');
  //   dynamicTemplateParsed = dynamicTemplateParsed.replace('[SUPERCHRIS]', '<button type="button">Click Me!</button>');
  //   dynamicTemplateParsed = dynamicTemplateParsed.replace('[CONTENT]', 'My super dynamic content');
  //   return dynamicTemplateParsed;
  // }

  maximumFlag: boolean = false;
  sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  async removeMultipleDocsFromOneDrive(){
  
    this.ngZone.run( async () =>{
      // console.log("selected docs in csn pop up", this.selectedDocs);
      var payloadItems = this.selectedDocs.map(
         selectedDoc =>{
               selectedDoc.userId = CONFIG.TEMP.USERID;
              
               
               return selectedDoc;
         }
       )
       // console.log("payloadItems", payloadItems);
  
      // console.log("removeMultipleDocsFromOneDrive Called in CSN pop up");
      
      this.progress = true;
      this.progressMessage =CONFIG.ONEDRIVE.REMOVE_MULTI_DOCUMENT_INPROGRESS;
  
       for await(const payload of payloadItems) {
        // console.log("----docId---"+  payload.docId+"-----started-----")
   let response =  await this.oneDriveOperationsService.deleteDocumentFromOneDrive(payload).toPromise();
    // console.log("response", response);
         //(response.status == 200 && response.message == "deleted successfully" )? successFulDocs.push(docId): "";
        //  (response.status == 504 && response.message != "error in deletion")? failureDocs.push(docId): "";
    // console.log("----docId---"+ payload.docId+"-----finished-----")
  }
  this.progress = false;
  this.progressMessage =""
  // console.log("this.progress", this.progress);
  this.body = CONFIG.ONEDRIVE.REMOVE_MULTI_DOCUMENT_SUCCESS;
  // console.log("this.body", this.body);
  this.selectedDocs = [];
    })

    
  }



  addToOneDriveTest(){
    const dataToSend = 'Test From Child comp.';
    this.sharedService.sendData(dataToSend);
  }

  popuUpshow : boolean = false;

  failedDocs : any;
  async addToOneDrive(){
    if(this.document.length > 1){
      this.addMultipleDocsForOneDrive();
      return;
    }
    this.ngZone.run(async () => {
      var source;
      // console.log("this.document.raw.source", this.document.raw.source);
      if (this.document.raw.source == "Veeva Vault QualityDocs Source-1" || this.document.raw.source == "Veeva Vault QualityDocs Source-2" || this.document.raw.source == "Veeva Vault QualityDocs") {
        source = "VVQD";
      }
      if (this.document.raw.source == "Veeva Vault MedComms") {
        source = "VVMC";
      }
      // console.log("source", source);
      //  if("sourcename" in  this.document.raw){
      //   // console.log(this.document.raw.sourcename?.[0]);
      //}
      //// console.log("document", this.document.raw.source);
      //  // console.log("document", this.document.raw.sourcename?.[0]);

      this.progress = true;
      this.progressMessage = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_INPROGRESS;
      var docId = this.document.raw['contentsourceid'];
      var payload: any = {
        docId: docId,
        userId: this.userId,

      }
      var uniqueId = "";
      var flag = true;
      var status = false;
      // var source =''
      do {
        if (uniqueId.length != 0) {
          payload['uniqueId'] = uniqueId;

          await this.sleep(5000);
        }
        // console.log("payload", payload);
        let content: any = "";
        if (source == "VVMC") {
          content = await (await this.oneDriveOperationsService.downloadVVMCDocumentToOneDrive(payload)).toPromise();
        }
        if (source == "VVQD") {
          content = await (await this.oneDriveOperationsService.downloadVVQDocumentToOneDrive(payload)).toPromise();
        }
        // console.log("oneDrive download Response", content);
        uniqueId = content.uniqueId;

        if (content.msg == "Upload Completed") {
          flag = false;
          status = true;

          

        }

        if (content.msg == "Upload Failed") {
          flag = false;

        }

      } while (flag);

      if (status) {
        // console.log("download complete");
        this.body = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_SUCCESS
        this.progress = false;
        this.progressMessage = "";
        this.onedrive_icon = CONFIG.ONEDRIVE.FILLED_ICON
        const dataToSend = 'download complete';
        this.sharedService.sendData(dataToSend);
        
      }
      else {
        // console.log("download failed");
        this.feature = 'removeFromOneDrive'
        this.body = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_FAILURE
        this.progress = false;
        this.failedDocs = "No Source Content"
        this.progressMessage = "";
        this.popuUpshow = true;
        // this.maximumFlag = true;
        // const dataToSend = 'download failed';
        // this.sharedService.sendData(dataToSend);
      }
      this.closeBtn = true;
    })
  }


  successFulDocs: any = [];
  failureDocs: any = [];
  payloadItems : any =[];
  oneDriveMultiplecontent: any = "";


  async addMultipleDocsForOneDrive() {
    // console.log("Documents", this.document);
    var uniqueId = "";
    var flag = true;
    var status = false;


    this.progress = true;
    let source: any;
    for(let i=0 ; i < this.document.length ; i++)
    {
      this.payloadItems.push(
        {
          "docId": this.document[i].docId,
          userId: this.userId,
          "source" : this.document[i].source
        }
      )
    }
    // console.log("this.payloadItems",this.payloadItems);
    for await (const payload of this.payloadItems) {
      do {
        if (uniqueId.length != 0) {
          payload['uniqueId'] = uniqueId;

          await this.sleep(5000);
        }
        // console.log("payload", payload);
      
        if (payload.source == "VVMC") {
          this.oneDriveMultiplecontent = await (await this.oneDriveOperationsService.downloadVVMCDocumentToOneDrive(payload)).toPromise();
        }
        if (payload.source == "VVQD") {
          this.oneDriveMultiplecontent = await (await this.oneDriveOperationsService.downloadVVQDocumentToOneDrive(payload)).toPromise();
        }
        // console.log("oneDrive download Response", this.oneDriveMultiplecontent);
        uniqueId = this.oneDriveMultiplecontent.uniqueId;

        if (this.oneDriveMultiplecontent.msg == "Upload Completed") {
          flag = false;
          status = true;
        }

        if (this.oneDriveMultiplecontent.msg == "Upload Failed") {
          flag = false;
        }

      } 
      while (flag);

      if (status) {
        // console.log("download complete");
        this.body = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_SUCCESS
        this.progress = false;
        this.progressMessage = "";
        this.onedrive_icon = CONFIG.ONEDRIVE.FILLED_ICON
        const dataToSend = 'download complete';
        this.sharedService.sendData(dataToSend);
      }
      else {
        // console.log("download failed");
        this.body = CONFIG.ONEDRIVE.DOWNLOAD_SINGLE_DOCUMENT_FAILURE
        this.progress = false;
        this.failedDocs = "No Source Content"
        this.progressMessage = "";
        const dataToSend = 'download failed';
        this.sharedService.sendData(dataToSend);
      }
    }
    this.progress = false;
    this.body = CONFIG.ONEDRIVE.DOWNLOAD_MULTIPLE_DOCUMENT_SUCCESS;

    const dataToSend = this.body;
    this.sharedService.sendData(dataToSend);
    
  }


  
  async removeFromOneDrive(){
    
    // console.log("th",this.document);
    // if(this.document.length > 1){
    //   this.addMultipleDocsForOneDrive();
    //   return;
    // }
    this.ngZone.run( async () => {


      this.progress = true;

      this.progressMessage= CONFIG.ONEDRIVE.REMOVE_SINGLE_DOCUMENT_INPROGRESS;
 
     var docId = this.document.raw['contentsourceid'];
     var source = "VVMC"
 
     let payload:any = 
     {
       "docId": docId,
       "userId": this.userId,
       "source": source
     }
     
      // console.log("payload", payload);
    
    let response:any =  await this.oneDriveOperationsService.deleteDocumentFromOneDrive(payload).toPromise();
    // console.log("response", response);
 
     if(response.status == 200){
      
      this.body=CONFIG.ONEDRIVE.REMOVE_SINGLE_DOCUMENT_SUCCESS;
      const dataToSend = response;
       this.sharedService.sendData(dataToSend);
//       const dataToSend = 'delete complete';
//       this.sharedService.sendData(dataToSend);
 
     }
     else{
       this.body=CONFIG.ONEDRIVE.REMOVE_SINGLE_DOCUMENT_FAILURE;
     }
     this.progress =false;
     this.closeBtn= true;
    })
    

  }



  selectedCollections: any =[];
  removedCollections:any=[];
  onCollectionCheckboxChange(e,list){
    if(e.target.checked){
      var enteredInRemovedCollections = false;
      this.removedCollections.forEach((value,index)=>{
        if(value==e.target.value){
          enteredInRemovedCollections = true;
          this.removedCollections.splice(index,1);
        } 
      })
      if(!enteredInRemovedCollections){
        this.selectedCollections.push(
          {
            id : 564,
            docid : list.id,
            name : list.name
          }
        );
      }
    }else{
      var enteredInSelectedCollections = false;
      this.selectedCollections.forEach((value,index)=>{
        if(value==e.target.value){
          enteredInSelectedCollections = true;
          this.selectedCollections.splice(index,1);
        } 
      })
      if(!enteredInSelectedCollections){
        this.removedCollections.push(e.target.value);
      }
    }
    // console.log(JSON.stringify(this.selectedCollections));
  }
  

  saveCollectionChanges(){
    // console.log("this.selectedCollections--->",this.selectedCollections);
    // console.log("this.removedCollections--->"+JSON.stringify(this.removedCollections));
    // console.log("this.document.raw.contentsourceid--->"+JSON.stringify(this.document.raw.contentsourceid));
    for(let i = 0 ;i < this.selectedCollections.length ; i++)
    {
      let payload = {
        "name": "undefined",
        "id": 564,
        "items": [
            {
                "docId": Number(this.selectedCollections[i].id),
                "docNumber": "MI-STG-0023-US"
            }
        ],
        "userId": this.userId,
        "operation": "UPDATE_DOC",
        "feature": "VVMC"
    }
    let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
    // console.log("response", response);
    this.renameFlag = false;
    this.loadAllCollections(response);
    this.ngOnInit();
    }

  }

  closeRenamePopup(){
  this.renameFlag = false;
  }

  alreadyUsedCollections:any = [];
  isDocPartOfCollection(collection:any){
    // // console.log("collection-->"+JSON.stringify(collection));
    var items = collection.items;
    if(items.length>0){
      for(let item of items){
        if(item.docId ==  this.document.raw.contentsourceid){
          this.alreadyUsedCollections.push(collection.id);
          // // console.log("this.alreadyUsedCollections-->"+JSON.stringify(this.alreadyUsedCollections));
          return true;
        }
      }
      
    }
    return false;
  }



  closePopup() {
    this.close.emit();
  }

  //Collection Functions

//  shareLink = "https://gmsasearch-sit.merck.com/index.html";
shareLink = "https://gmsasearch-sit.merck.com/index.html";
 
 renameFlag : boolean = false;

 renameValue : any
 renameTextValue(event){
  this.renameValue = event.target.value
  // console.log("value",event.target.value);
 }
 newnameValue : any;
 newnameTextValue(event){
  this.newnameValue = event.target.value
  // console.log("value",event.target.value);
 }

 seletedRenameList :any;
 selecedNameList: any;
async rename(list){
  this.renameFlag = true;
  this.seletedRenameList = list;
}

 renameUpdate(){
  let payload = {
    "name": this.renameValue,
    "id": this.seletedRenameList.id,
    "items": [this.seletedRenameList.items],
    "userId": this.userId,
    "operation": "UPDATE_COLLECTION",
    "feature": "VVMC"
}
  let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
  // console.log("response", response);
  this.renameFlag = false;
  this.loadAllCollections(response);
  this.ngOnInit();
  // const dataToSend = 'download complete';
  // this.sharedService.sendData(dataToSend);
}
deleteFlag : boolean = false;

closeDeletePopup(){
  this.deleteFlag = false;
}

deleteCollection(){
  let payload = 
  {
    "name": "undefined",
    "id": this.selectedDeleteList.id,
    "items": [],
    "userId":this.userId,
    "operation": "DELETE_COLLECTION",
    "feature": ""
}
  let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
  // console.log("response", response);
  this.renameFlag = false;
  this.loadAllCollections(response);
  this.ngOnInit();
}

newnameFlag : boolean= false;

selectedDeleteList : any;
delete(list){
  this.selectedDeleteList = list;
  this.selecedNameList = list.name;
  this.deleteFlag =true;
  // console.log("list",list);
}

share(list){
  // console.log("list",list);
  var email = "";
  var subject = 'V&I Search Navigator: Asset share';
  var emailBody = ''
   //emailBody +="%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-sit.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);

  emailBody +="%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-sit.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
  emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
  emailBody += this.disclaimer_txt;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
}

saveCollections(){

  let payload = {
    "name": "undefined",
    "id": 469,
    "items": [
        {
            "docId": 4141,
            "docNumber": "MDD-0013"
        }
    ],
    "userId": this.userId,
    "operation": "UPDATE_DOC",
    "feature": "VVMC"
}
let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
// console.log("response", response);
this.newnameFlag = false;
this.loadAllCollections(response);
this.ngOnInit();
}

shareDocument(){
  var email = "";
  var subject = 'V&I Search Navigator: Asset share';
  var emailBody = ''
   //emailBody +="%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-sit.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+"272");

  emailBody +="%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-sit.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+"272");
  emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
  emailBody += this.disclaimer_txt;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
}

disclaimer_label = "About V&I Search Navigator:"
disclaimer_txt = "The V&I Search Navigator is an advanced search engine tool that provides GMSA colleagues with a simplified approach for efficiently and effectively searching across multiple repositories for scientific and related content.";

addremoveCollections()
{
  this.newnameFlag = true;
}

addCollections (){
  let payload = 
  {
    "name": this.newnameValue,
    "id": "",
    "items": [],
    "userId": [
      this.userId
    ],
    "collectionid": "",
    "statustxt": "created",
    "feature": "VVMC"
}
  let response: any = (this.collectionsService.createCollection(payload)).toPromise();
  // console.log("response", response);
  this.newnameFlag = false;
  this.loadAllCollections(response);
  this.ngOnInit();
}

closeNewCollection(){
  this.newnameFlag = false;

}

loadAllCollections(result:Result){
  this.ngZone.run(() =>{
  this.feature="addCollections";

  // var useridDecrypted = this.auth.getUserId();
  var tab = 'VVMC';
  var data = {
        "collectionId": "",
        "userId": this.userId,
        "tab": "VVMC"
    }
    this.collectionsService.getAllCollections(data).subscribe((collections)=>{
    this.collectionslist = collections.collectionlist;
    //  this.collectionslist= [];
   })
  })
}


closeMyCollectionEmptyPopup(){
  this.emptyCollectionPopupEmit.emit();
}

closeMydriveEmpty(){
  this.emptyDrivePopupEmit.emit();
}


}


