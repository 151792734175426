import { ChangeDetectorRef, Component, Input,Output,EventEmitter,OnInit, inject } from '@angular/core';
import { ExportToExcelService } from '../export-to-excel.service';
import {ResultsListDataService} from '../results-list-data.service';
import {DOCUMENT} from '@angular/common';
import { COLUMN_MAPPING, CONFIG, ENVIRONMENT } from 'src/environments/environment.development';
import * as XSLX from 'xlsx';
import { IfStmt } from '@angular/compiler';
import { Customeventlogging } from '../customevent-logging.service';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';




@Component({
  selector: 'app-export-to-excel',
  templateUrl: './export-to-excel.component.html',
  styleUrls: ['./export-to-excel.component.css']
})
export class ExportToExcelComponent implements OnInit {
devAccessToken: string = '';
 @Input() exportToExcelPopUp;
 @Output() close = new EventEmitter<void>();
 private document:any = inject(DOCUMENT);
   
   constructor( private resultListDataService: ResultsListDataService ,private cookieService:CookieService,
    private eventLoggingService :Customeventlogging,private cookie : CookieServiceWrapper,
    private cdr: ChangeDetectorRef, private exportToExcelService:ExportToExcelService){
    // // console.log("exportToExcelPopUp", this.exportToExcelPopUp);
    //  // console.log("ExportToExcelComponent called")
    //  // console.log("ExportToExcelComponent",this.resultListDataService.results);
   }
    disableElement(id){
    this.document.getElementById(id).disabled = true;
    this.document.getElementById(id).style.cursor="not-allowed";
    //document.getElementById(id).style.backgroundColor="grey";
    this.document.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
    
     }

   closeMainTab(){
      this.close.emit();
   }
    async ngOnInit() {
      this.devAccessToken = this.cookieService.get('st');
       // console.log("ngOnInit called");
       // console.log("ExportToExcelComponent",this.resultListDataService.requestBody);
       // console.log("ExportToExcelComponen total count",this.resultListDataService.totalCount);

     
    }

    isCollapsed = true;

     exportToExcelCollapsibles = {
              commonColumns: true,
              gmsaNews: true,
              vvqd: true,
              vvmc: true,
              ideaexchange: true,
              promomats:true,
              pie:true,
              stp:true,
              modelRepo:true
              
     };
  toggleCollapse(choice) {
    
      switch(choice){
       case "commonColumns" :  this.exportToExcelCollapsibles.commonColumns = !this.exportToExcelCollapsibles.commonColumns; break;
       case "gmsaNews" :  this.exportToExcelCollapsibles.gmsaNews = !this.exportToExcelCollapsibles.gmsaNews; break;
       case "vvmc" :  this.exportToExcelCollapsibles.vvmc = !this.exportToExcelCollapsibles.vvmc; break;
       case "vvqd" :  this.exportToExcelCollapsibles.vvqd = !this.exportToExcelCollapsibles.vvqd; break;
       case "ideaexchange" :  this.exportToExcelCollapsibles.ideaexchange = !this.exportToExcelCollapsibles.ideaexchange; break;
       case "promomats" :  this.exportToExcelCollapsibles.promomats = !this.exportToExcelCollapsibles.promomats; break;
       case "pie" :  this.exportToExcelCollapsibles.pie = !this.exportToExcelCollapsibles.pie; break;
       case "stp" :  this.exportToExcelCollapsibles.stp = !this.exportToExcelCollapsibles.stp; break;
       case "modelRepo" :  this.exportToExcelCollapsibles.modelRepo = !this.exportToExcelCollapsibles.modelRepo; break;
      }
    
  }

  
    async getFinalResults() {
        let finalResult:any=[];
      var numberOfResults = parseInt(this.resultListDataService.totalCount.toString());
      console.log("numberOfResults",numberOfResults);
      var maxResults:any = 500;
      var resultsSplitArray:any = [];
      for (var counter = 0; ; counter++) {
        if (numberOfResults > maxResults) {
          resultsSplitArray.push(maxResults);
          numberOfResults = numberOfResults - maxResults;
          counter++;
        }
        else {
          resultsSplitArray.push(numberOfResults);
          break;
        }
      }
       console.log("resultsSplitArray",resultsSplitArray); 
      var counter = 0;
      for (var x = 0; x < resultsSplitArray.length; x++) {
        // console.log("counter", counter);
        var result = await this.getResults(counter);
        // console.log("result", result);
        counter = counter + resultsSplitArray[x];
         finalResult = finalResult.concat(result);
        // console.log("x value is", resultsSplitArray[x]);
      }
      return finalResult;
    }

    async getResults(firstResult:any):Promise<any> {
      // console.log("getresults");
      console.log("this.resultListDataService.requestBody",this.resultListDataService.requestBody.toString());
      var searchRequestPayload:any= JSON.parse(this.resultListDataService.requestBody.toString());
      var apiUrl = CONFIG.EXPORT_TO_EXCEL.API_URL
      // var apiUrl = `https://merckholdingsllcnonproduction1xzti28zp.org.coveo.com/rest/search/v2?organizationId=${this.cookie.get('oid')}`
      var devToken = this.cookie.get('st');
      // console.log("APIURL", apiUrl);
      try {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          xhr.open('post', apiUrl, true);
          xhr.setRequestHeader('Authorization', 'Bearer ' + devToken);
          xhr.setRequestHeader('Content-type', 'application/json');
          xhr.onload = function () {
            var status = xhr.status;
            if (status == 200) {
              var response = JSON.parse(this.responseText);
              resolve(response.results);
            } else {
              reject(status);
            }
          };

          searchRequestPayload.firstResult = firstResult;
          searchRequestPayload.numberOfResults = 500;
          // console.log("searchRequestPayload.firstResult", searchRequestPayload.firstResult)
          xhr.send(JSON.stringify(searchRequestPayload));
        });
      }
      catch (error) {
       // // console.log('error', error.message);
       // document.getElementById("loader").style.display = "none";
        alert("Something went wrong! Please try later!");
      }
    }
  
     enableElement(id){
      this.document.getElementById(id).disabled = false;
      //document.getElementById(id).style.cursor="pointer";
      //document.getElementById(id).style.background="linear-gradient(to right, #045F5F 50%,teal 20%)";
      //document.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
      //document.getElementById(id).style.background= "";
      this.document.getElementById(id).removeAttribute("style");
    
         }


    async exportToExcel(){
      
      event?.stopPropagation();
             // console.log("exportToExcel");
             // console.log("total count",  this.resultListDataService.totalCount.toString() == "5275");
             var count  = parseInt( this.resultListDataService.totalCount.toString());
             // console.log("count", typeof count);
             // console.log("count", count);
            // var count = this.resultListDataService.totalCount.getValue();
             //// console.log("count", count);
             if (this.checkIfOptionsNotSelected() == false) {
              this.document.getElementById("info").style.color = "red";
              //this.document.getElementById("info").innerHTML = "Please select at least one column to export";
              this.document.getElementById("info").innerHTML =  CONFIG.EXPORT_TO_EXCEL.SELECT_ATLEAST_ONE_COLUMN

              // console.log("return");
              return;
            }
            else{
              this.document.getElementById("info").innerHTML = "";
            }

           // // console.log("value",count.value);

            if(count > 5000){
            
               // this.document.getElementById("info").style.color = "#045D5D";
               
               // this.document.getElementById("info").innerHTML = "Only the first 5000 records will be exported";
               this.document.getElementById("info").innerHTML = CONFIG.EXPORT_TO_EXCEL.ONLY_FIRST_5000_COLUMNS;
              }
              this.disableElement("exportToExcelBtn");
              this.document.getElementById("downloading").innerHTML= "Downloading...";
              var checkedValue:any = null;
  var selectedItems:any = [];
  var inputElements:any = this.document.getElementsByName('export');
  for (var i = 0; inputElements[i];i++) {
    if (inputElements[i].checked) {
      checkedValue = inputElements[i].value;
      // console.log("checkedValue", checkedValue);
      selectedItems.push(checkedValue);
    }
  }
  //console.log("selected Items", selectedItems);

  var selectAllItemsExclude = ["selectAll","common","gmsanews","vvmc","vvqd","ideaExchange","promomats","pie","stp","modelRepo"];

   selectedItems.forEach( (excludeItem,i) =>{
         
                   if(selectAllItemsExclude.includes(excludeItem)){
                    // console.log("removed values", excludeItem);
                          selectedItems.splice(i,1);
                   }
      
    })

    // console.log("selectedItems after removal", selectedItems);
   

  var finalResults = await this.getFinalResults();
  // console.log("finalResults", finalResults);
    // custom Events
   this.generateExcel(selectedItems, finalResults);
    }

     generateExcel(selectedItems, finalResults) {
      this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Export-Excel-Actual', selectedItems :selectedItems},'Export-Excel-Actual');

      var results = finalResults;
      // console.log("results in generateExcel", results);
      var documentsList = this.getDocumentsList(results);

      // console.log("documentsList", documentsList);
     // return;

      var finalSelectedItems:any = [];
  for (var i = 0; i < selectedItems.length; i++) {
    var selectedItem = selectedItems[i];
    // console.log("selectedItem", selectedItem);
    
      var actualSelectedItem = COLUMN_MAPPING[selectedItem];
    
   
    finalSelectedItems.push(actualSelectedItem);
  }
  // console.log("finalSelectedItems",finalSelectedItems);

  // reorders the documentlist based on finalSelectedItems

  var result = documentsList.filter(function (o1) {
    return documentsList.some(function (o2) {
      return o1.id === o2.id;
    });
  }).map(function (o) {
    return finalSelectedItems.reduce(function (newo, name) {
      newo[name] = o[name];
      return newo;
    }, {});
  });

  // console.log(" final result before export", result);

  const ws:XSLX.WorkSheet =   XSLX.utils.json_to_sheet(result);
  const wb:XSLX.WorkBook = XSLX.utils.book_new();
  XSLX.utils.book_append_sheet(wb,ws,'Exported Data');
  XSLX.writeFile(wb,  "V&I Search Navigator" + "_" + this.getTimeStamp()+".xlsx");
  this.enableElement("exportToExcelBtn");
  this.document.getElementById("downloading").innerHTML= "";
  this.document.getElementById("info").style.color = "teal";
  //this.document.getElementById("info").innerHTML ="Selected columns exported to excel";
  this.document.getElementById("info").innerHTML = CONFIG.EXPORT_TO_EXCEL.EXPORT_SUCCESS
     }
      getDocumentsList(documents) {
      // console.log("documents", documents);
     // return;
      var finalDocumentsJSON:any = [];
        for (var j = 0; j < documents.length; j++) {
          var document = documents[j];
          var finalObject:any = {};

                    if(document.raw.syssource == "GMSA News"){
               if("printableUri" in document){

                finalObject["Source Link"] = "printableUri" in document ? document.printableUri : "";
               }
              }
              else{
                if("uri" in document){
                  // console.log("URI");
                  finalObject["Source Link"] = "uri" in document ? document.uri : "";
                 }
              }
               //console.log("document.raw.syssource", document.raw.syssource);
              if(document.raw.syssource == "Veeva Vault MedComms"){
                     // console.log("inside vvmc" + j);
                         // console.log("document.raw.vvmctherapeuticarea?.[0]", "vvmctherapeuticarea" in document.raw ? document.raw.vvmctherapeuticarea?.[0] : "not there");
                         finalObject["Effective Date"] = "vvmcgvdeffectivedate" in document.raw ? this.convertEpochToHuman(document.raw.vvmcgvdeffectivedate) : "";
                finalObject["Document Type"] = "contentdocumenttype" in document.raw ? document.raw.contentdocumenttype?.[0] : "";
                  if(document.raw.veap_obj_id){
                    finalObject["Document Type"] =  "";
                  }

                 
                finalObject["Document Sub Type"] = "contentdocumentsubtype" in document.raw ? document.raw.contentdocumentsubtype?.[0] : "";
                finalObject["Therapeutic Area"] = "vvmctherapeuticarea" in document.raw ? document.raw.vvmctherapeuticarea?.[0] : "";
              }
              else{
               
                finalObject["Document Type"] = "qddocumenttype" in document.raw ? document.raw.qddocumenttype?.[0] : "";
                if(document.raw.veap_obj_id){
                  finalObject["Document Type"] =  "";
                }
                finalObject["Document Sub Type"] = "qddocumentsubtype" in document.raw ? document.raw.qddocumentsubtype?.[0] : "";
                finalObject["Therapeutic Area"] = "contenttherapeuticarea" in document.raw ? document.raw.contenttherapeuticarea.toString() : "";
                finalObject["Effective Date"] = "qdeffectivedate" in document.raw ? this.convertEpochToHuman(document.raw.qdeffectivedate) : "";
              }
              
          // SP - "GMSA News"

          if(document.raw.syssource == "GMSA News"){

          finalObject["Document Name / Title"] = "title" in document ? document.title : "";
          finalObject["Author"] = "author" in document.raw ? document.raw.author : "";
          finalObject["Announcements"] = "contentspcategoryannouncements" in document.raw ? document.raw.contentspcategoryannouncements.toString() : "";
          finalObject["Functional Areas"] = "contentspcategoryfunctionalareas" in document.raw ? document.raw.contentspcategoryfunctionalareas.toString() : "";
          finalObject["GMA Capabilities"] = "contentspcategorygmacapabilities" in document.raw ? document.raw.contentspcategorygmacapabilities.toString() : "";
          finalObject["Leader Messages"] = "contentspcategoryleadermessages" in document.raw ? document.raw.contentspcategoryleadermessages.toString() : "";
          finalObject["Published Date"] = "gmsapublisheddate" in document.raw ? this.convertEpochToHuman(document.raw.gmsapublisheddate) : "";
          finalObject["Region"] = "gmsaregion" in document.raw ? document.raw.gmsaregion.toString() : "";
          finalObject["Special Topics"] = "contentspcategoryspecialtopics" in document.raw ? document.raw.contentspcategoryspecialtopics.toString() : "";
          }
          // else{

          //   finalObject["Document Name"] =  "";
          // finalObject["Author"] =  "";
          // finalObject["Announcements"] = "";
          // finalObject["Functional Areas"] = "";
          // finalObject["GMA Capabilities"] =  "";
          // finalObject["Leader Messages"] =  "";
          // finalObject["Published Date"] = "";
          // finalObject["Region"] =  "";
          // finalObject["Special Topics"] =  "";
          // }

          if(document.raw.syssource ==  "Veeva Vault MedComms"){
           // VVMC  - Veeva Vault MedComms
           finalObject["Document Number"] = "contentdocumentnumber" in document.raw ? document.raw.contentdocumentnumber : "";
           if(document.raw.veap_obj_id){
            finalObject["Document Number"] =  "";
          }
           finalObject["Document Name / Title"] = "title" in document ? document.title : "";
      finalObject["Description"] = "contentdescription" in document.raw ? document.raw.contentdescription : "";
      
      finalObject["Version"] = "contentversion" in document.raw ? document.raw.contentversion : "";
      finalObject["Sub Therapeutic Area"] = "gmsa_sub_therapeutic_area" in document.raw ? document.raw.gmsa_sub_therapeutic_area.toString() : "";

      finalObject["Intended Use"] = "contentintendeduse" in document.raw ? document.raw.contentintendeduse?.[0] : "";
      finalObject["Country"] = "gmsacountry" in document.raw ? document.raw.gmsacountry.toString() : "";
      finalObject["Status"] = "contentitemstatus" in document.raw ? document.raw.contentitemstatus?.[0] : "";
      finalObject["Product"] = "contentproductname" in document.raw ? document.raw.contentproductname?.[0] : "";
      
      finalObject["Language"] = "contentlanguage" in document.raw ? document.raw.contentlanguage?.[0] : "";
      finalObject["Proactive"] = "contentproactive" in document.raw ? document.raw.contentproactive : "";
        if(document.raw.contentdocumenttype?.[0] == "Listening Priorities"){
          finalObject["Content In CRM"] =  "";
        }
        else{
          finalObject["Content In CRM"] = "contentinclm" in document.raw ? document.raw.contentinclm : "";
        }
      
      finalObject["Content In MI"] = "contentinirms" in document.raw ? document.raw.contentinirms : "";
      finalObject["Approval Date"] = "gmsaapprovaldate" in document.raw ? this.convertEpochToHuman(document.raw.gmsaapprovaldate) : "";

      finalObject["Document ID"] = "contentsourceid" in document.raw ? document.raw.contentsourceid : "";
      
      if(document.raw.veap_obj_id){
        finalObject["Document ID"] =  "";
      }
      //finalObject["Source Link"] = "uri" in document ? document.uri : "";

      finalObject["Therapeutic Area (VEAP)"] = "vvmctherapeuticareaveap" in document.raw ? document.raw.vvmctherapeuticareaveap : "";
      finalObject["REV/OPS ID"] = "vvmcgvdveapid" in document.raw ? document.raw.vvmcgvdveapid?.[0] : "";
      finalObject["Key Contact"] = "vvmcgvdkeycontact" in document.raw ? document.raw.vvmcgvdkeycontact : "";
      finalObject["Department (VEAP)"] = "vvmcgvddepartment" in document.raw ? document.raw.vvmcgvddepartment : "";
      finalObject["Model Type"] = "vvmc_core_model_type" in document.raw ? document.raw.vvmc_core_model_type : "";
      finalObject["DRC Approval Date"] = "vvmc_core_dcr_approval_date" in document.raw ? this.convertEpochToHuman(document.raw.vvmc_core_dcr_approval_date) : "";
      finalObject["Activity Type"] = "vvmc_core_activity_type" in document.raw ? document.raw.vvmc_core_activity_type : "";
      finalObject["Payor Use Only"] = "payer" in document.raw ? document.raw.payer : "";
      finalObject["Payor Region"] = "payerregion" in document.raw ? document.raw.payerregion : "";
      finalObject["Payor Topic"] = "payertopic" in document.raw ? document.raw.payertopic : "";
     // NIS and ESA Related  
     finalObject["Planned/Actual Report Completion Date"] = "niscompletiondate" in document.raw ? this.convertEpochToHuman(document.raw.niscompletiondate) : "";
     finalObject["Planned/Actual Protocol Approval Date"] = "nisapprovaldate" in document.raw ? this.convertEpochToHuman(document.raw.nisapprovaldate) : "";
     finalObject["Activity Status (REV/OPS)"] = "nisactivitystatus" in document.raw ? document.raw.nisactivitystatus?.[0] : "";
     finalObject["Activity type (REV/OPS)"] = "veapactivitytype" in document.raw ? document.raw.veapactivitytype?.[0] : "";
     finalObject["Country (REV/OPS)"] = "veapcountry" in document.raw ? document.raw.veapcountry.toString() : "";
     finalObject["Customer Business Line"] = "veapcustomerbusline" in document.raw ? document.raw.veapcustomerbusline : "";
     finalObject["Is LDG? (REV/OPS)"] = "veapisldg" in document.raw ? document.raw.veapisldg?.[0] : "";
     finalObject["MK/V Number"] = "veapmkvnumber" in document.raw ? document.raw.veapmkvnumber : "";
     finalObject["Name of Product (REV/OPS)"] = "veapproduct" in document.raw ? document.raw.veapproduct?.[0] : "";
     finalObject["Steward/Contact (REV/OPS)"] = "veapcontact" in document.raw ? document.raw.veapcontact?.[0] : "";
     finalObject["REV/OPS ID"] = "veap_obj_title" in document.raw ? document.raw.veap_obj_title?.[0] : "";
     finalObject["Study Objective"] = "veapstudyobjective" in document.raw ? document.raw.veapstudyobjective : "";
     finalObject["Therapeutic Area (REV/OPS)"] = "veaptherapeuticarea" in document.raw ? document.raw.veaptherapeuticarea?.[0] : "";
     finalObject["Indication/Disease (REV/OPS)"] = "veapindication" in document.raw ? document.raw.veapindication?.[0] : "";

    
    
       
    
     
    }
          // else{

          //   finalObject["Document Number"] =  "";
          //   finalObject["Document Name"] =  "";
          //   finalObject["Description"] =  "";
            
          //   finalObject["Intended Use"] =  "";
          //   finalObject["Country"] = "";
          //   finalObject["Status"] = "";
          //   finalObject["Product"] =  "";
            
          //   finalObject["Language"] =  "";
          //   finalObject["Proactive"] =  "";
          //   finalObject["Content In CRM"] =  "";
          //   finalObject["Content In IRMS"] = "";
          //   finalObject["Approval Date"] =  "";
          //   finalObject["Document ID"] =  "";
            
          //   //finalObject["Source Link"] = "uri" in document ? document.uri : "";
      
          //   finalObject["Therapeutic Area (VEAP)"] =  "";
          //   finalObject["VEAP ID OR REV/OPS ID"] =  "";
          //   finalObject["Key Contact"] =  "";
          //   finalObject["Department (VEAP)"] =  "";
          //   finalObject["Model Type"] =  "";
          //   finalObject["DRC Approval Date"] =   "";
          //   finalObject["Activity Type"] = "";
          //   finalObject["Payor Use Only"] =  "";
          //   finalObject["Payor Region"] =  "";
          //   finalObject["Payor Topic"] =  "";
          // }
      

      //VVQD 
                     if(document.raw.syssource == "Veeva Vault QualityDocs Source-1" || document.raw.syssource == "Veeva Vault QualityDocs Source-2" || document.raw.syssource == "Veeva Vault QualityDocs"){

                      finalObject["Document Name / Title"] = "title" in document ? document.title : "";
                      finalObject["Source"] = "syssource" in document.raw ? document.raw.syssource : "";
                
                      finalObject["Status"] = "contentitemstatus" in document.raw ? document.raw.contentitemstatus?.[0] : "";
                      //finalObject["Source Link"] = "uri" in document ? document.uri : "";
                      finalObject["MRL Classification"] = "mrlclassification" in document.raw ? document.raw.mrlclassification : "";
                      finalObject["Sensitivity Classification"] = "qdsensitivityclassification" in document.raw ? document.raw.qdsensitivityclassification : "";
                      finalObject["Impacted Countries/Regions"] = "impactedcountry" in document.raw ? document.raw.impactedcountry : "";
                      finalObject["Version"] = "contentversion" in document.raw ? document.raw.contentversion : "";
                      finalObject["Impacted Department"] = "qdimpacteddepartment" in document.raw ? document.raw.qdimpacteddepartment : "";
                      finalObject["Document Number"] = "contentdocumentnumber" in document.raw ? document.raw.contentdocumentnumber : "";
                     
                      if(document.raw.veap_obj_id){
                        finalObject["Document Number"] =  "";
                      }
                      finalObject["Final Date"] = "qdfinaldate" in document.raw ? this.convertEpochToHuman(document.raw.qdfinaldate) : "";
                      finalObject["Document Owner"] = "contentdocumentowner" in document.raw ? document.raw.contentdocumentowner?.[0] : "";
                      finalObject["Version Owner"] = "qddocumentversionowner" in document.raw ? document.raw.qddocumentversionowner : "";
                
                     }

                     if(document.raw.syssource == "Idea Exchange"){

                      finalObject["Document Name / Title"] = "title" in document ? document.title : "";
                     
                
                      finalObject["Status"] = "status" in document.raw ? document.raw.status: "";
                      //finalObject["Source Link"] = "uri" in document ? document.uri : "";
                      finalObject["Submitter"] = "submitter" in document.raw ? document.raw.submitter : "";
                      finalObject["Region"] = "region" in document.raw ? document.raw.region : "";
                      finalObject["Category"] = "category" in document.raw ? document.raw.category : "";
                      finalObject["Event"] = "event" in document.raw ? document.raw.event : "";
                      finalObject["Submission Date"] = "submission_date" in document.raw ? this.dateConversionForSubmissionDate(document.raw.submission_date): "";
                      finalObject["Therapeutic Area(s)"] = "therapeutic_area" in document.raw ? document.raw.therapeutic_area : "";
      
                     }

                     if(document.raw.syssource == "Veeva Vault PromoMats"){
                      finalObject["Classification"] = "gmsavvpmclassification" in document.raw ? document.raw.gmsavvpmclassification.toString() : "";
                      finalObject["Resource Type"] = "gmsavvpmresourcetype" in document.raw ? document.raw.gmsavvpmresourcetype.toString() : "";
                      finalObject["Approval for Distribution Date"] = "approvaldatedistribution" in document.raw ? this.approvalDateForDistributionDateFormat(document.raw.approvaldatedistribution): "";
                      finalObject["Steward/Contact"] = "steward" in document.raw ? document.raw.steward?.[0]: "";
                      finalObject["Language"] = "vvpmcontentlanguage" in document.raw ? document.raw.vvpmcontentlanguage : ""; 
                      finalObject["Product"] = "vvpmcontentproductname" in document.raw ? document.raw.vvpmcontentproductname.toString() : "";
                      finalObject["Country"] = "vvpmcountry" in document.raw ? document.raw.vvpmcountry.toString() : "";
                      finalObject["Document Name / Title"] = "title" in document ? document.title : "";
                      finalObject["Document Sub Type"] = "vvpmcontentdocumentsubtype" in document.raw ? document.raw.vvpmcontentdocumentsubtype : "";
                      finalObject["Document Type"] = "vvpmcontentdocumenttype" in document.raw ? document.raw.vvpmcontentdocumenttype : "";
                      finalObject["Therapeutic Area"] = "gmsavvpmfranchise" in document.raw ? document.raw.gmsavvpmfranchise?.[0] : "";
                      finalObject["Title"] = "title" in document.raw ? document.raw.title : "";
                      finalObject["Status"] = "contentitemstatus" in document.raw ? document.raw.contentitemstatus?.[0] : "";
                    }

                    if(document.raw.syssource == "PIE"){
                      finalObject["Tumor or Disease"] = "pie_disease_type" in document.raw ? document.raw.pie_disease_type.toString() : "";
                      finalObject["Type of Engagement"] = "pie_type_patient_engagement" in document.raw ? document.raw.pie_type_patient_engagement.toString() : "";
                      finalObject["Steward"] = "pie_author" in document.raw ? document.raw.pie_author[0].toString() : "";
                      finalObject["Region"] = "pie_region" in document.raw ? document.raw.pie_region.toString() : "";
                      finalObject["Year"] = "pie_date" in document.raw ? document.raw.pie_date.toString() : "";
                      finalObject["Therapeutic Area"] = "pie_therapeutic_area" in document.raw ? document.raw.pie_therapeutic_area[0]: "";
                      finalObject["Clinical Trials"] = "pie_clinicaltrial" in document.raw ? document.raw.pie_clinicaltrial.toString() : "";
                      finalObject["Access"] = "pie_access" in document.raw ? document.raw.pie_access.toString() : "";
                      finalObject["Patient Experience"] = "pie_patientexperience" in document.raw ? document.raw.pie_patientexperience.toString() : "";
                      finalObject["Patient Feedback"] = "pie_patientfeedback" in document.raw ? document.raw.pie_patientfeedback.toString() : "";
                      finalObject["Document Name / Title"] = "systitle" in document.raw ? document.raw.systitle : "";
                      finalObject["Source Link"] = "printableUri" in document ? document.printableUri : "";
                    }

                    if(document.raw.syssource == "Strategic Partnerships"){

                      finalObject["Title"] = "stp_title" in document.raw ? document.raw.stp_title : "";
                      finalObject["Description"] = "stp_description" in document.raw ? this.removeWord(document.raw.stp_description) : "";
                      finalObject["Source Link"] = "printableUri" in document ? document.printableUri : "";
                    }

                    if(document.raw.syssource == "Model Repository"){

                      finalObject["Document Name / Title"] = "title" in document.raw ? document.raw.title : "";
                      finalObject["Source Link"] = "printableUri" in document ? document.printableUri : "";
                      finalObject["Information Classification"] = "mr_information_classification" in document.raw ? document.raw.mr_information_classification : "";
                      finalObject["Stage of Model Development"] = "mr_stage_model_development" in document.raw ? document.raw.mr_stage_model_development[0].toString() : "";
                      finalObject["Model Steward"] = "mr_model_steward" in document.raw ? document.raw.mr_model_steward[0].toString() : "";
                      finalObject["Value and Implementation Lead"] = "mr_value_implementation_lead" in document.raw ? document.raw.mr_value_implementation_lead : "";
                      finalObject["Country"] = "mr_country" in document.raw ? document.raw.mr_country : "";
                      finalObject["Name of Product"] = "mr_name_product" in document.raw ? document.raw.mr_name_product[0].toString() : "";
                      finalObject["Indication/Disease"] = "mr_indication" in document.raw ? document.raw.mr_indication.toString() : "" + "/"+ "mr_disease" in document.raw ? document.raw.mr_disease.toString() : "";
                      finalObject["Model Type"] = "mr_model_type" in document.raw ? document.raw.mr_model_type[0].toString() : "";
                      finalObject["Version Number"] = "mr_version_number" in document.raw ? document.raw.mr_version_number.toString() : "";
                      finalObject["Global/Adaptation"] = "mr_global" in document.raw ? document.raw.mr_global.toString() : "";
                      finalObject["Therapeutic Area"] = "mr_therapeutic_area" in document.raw ? document.raw.mr_therapeutic_area: "";
                    }

                     

                      


                    //  else{
                    //   finalObject["Document Name"] =  "";
                    //   finalObject["Source"] = "";
                
                    //   finalObject["Status"] =  "";
                    //   //finalObject["Source Link"] = "uri" in document ? document.uri : "";
                    //   finalObject["MRL Classification"] =  "";
                    //   finalObject["Sensitivity Classification"] = "";
                    //   finalObject["Impacted Countries/Regions"] =  "";
                    //   finalObject["Version"] =  "";
                    //   finalObject["Impacted Department"] ="";
                    //   finalObject["Document Number"] =  "";
                     
                    //   finalObject["Final Date"] =  "";
                    //   finalObject["Document Owner"] = "";
                    //   finalObject["Version Owner"] = "";
                

                    //  }
     
          finalDocumentsJSON.push(finalObject);
        }
        console.log(finalDocumentsJSON);
       
        return finalDocumentsJSON;
      }

      removeWord(sentence: String) {
        try{
          return sentence.replace('Description', '')  ;
        }catch(err){
          return ''
        }
      }

      dateConversionForSubmissionDate(value){
        var parts = value.split(".");

           var year=parts?.[0];
           var month=parts?.[1];
           var day =parts?.[2];

           let monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
           let monthName =monthNames[parseInt(month,10) - 1];
          let formattedDate =  `${monthName}-${day}-${year}`;
          return formattedDate;
      }

      approvalDateForDistributionDateFormat(date){
      

        // 2024-06-26
 
        var parts = date.split("-");
        var year=parts?.[0];
           var month=parts?.[1];
           var day =parts?.[2];
           let monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
           let monthName =monthNames[parseInt(month,10) - 1];
          let formattedDate =  `${monthName}-${day}-${year}`;
          return formattedDate;
          
      }
      

       getTimeStamp() {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        var date = new Date();
        var customDate:any = date.getDate();
        if (customDate <= 9) {
          customDate = "0" + customDate;
        }
        var timeStamp = date.getFullYear() + monthNames[date.getMonth()] + customDate;
        return timeStamp;
      }
       convertEpochToHuman(epoch) {
      
        var date = new Date(epoch);
        const monthNames = ["January", "Febraury", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

      
       
        return monthNames[date.getMonth()] + "-" + this.addZero(date.getDate()) + "-" + date.getFullYear();
      
      }

      addZero(input){
        if (input <= 9) {
          input = "0" + input;
        }
        return input;
      }
    

     checkIfOptionsNotSelected() {
      //// console.log("checkIfOptionsNotSelected")
      var isAnyOptionSelected = false;
      var inputElements = this.document.getElementsByName('export');
      for (var i = 0; inputElements[i]; i++) {
        if (inputElements[i].checked == true) {
          isAnyOptionSelected = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }
      return isAnyOptionSelected;
    }
    
    
    checkBoxHandler(id:any){
         // console.log("checkBoxHandler");
      var element:any = this.document.getElementById(id);
       console.log("element", element);

      console.log("checkBoxHandler");

    
    
   
      // alert("element.checked-->"+element.checked);
      if (element.checked) {
        
        element.checked = false;
        
        
      
        id == "selectAll" ? this.selectAllOptions() : "";
        id == "gmsanews" ? this.selectGMSANewsOptions():"";
        id == "vvmc" ? this.selectVVMCOptions():"";
        id == "vvqd" ? this.selectVVQDOptions():"";
        id == "ideaExchange" ? this.selectIdeaExchangeOptions(): "";
        id == "promomats" ? this.selectVVPMOptions(): "";
        id == "pie" ? this.selectPIEOptions(): "";
        id == "stp" ? this.selectSTPOptions(): "";
        id == "common" ? this.selectCommonColumnsOptions():"";
        id  == "modelRepo" ? this.selectModelRepoOptions():"";
      }
      else {
        element.checked = true;
        id == "selectAll" ? this.selectAllOptions() : "";
        id == "gmsanews" ? this.selectGMSANewsOptions():"";
        id == "vvmc" ? this.selectVVMCOptions():"";
        id == "vvqd" ? this.selectVVQDOptions():"";
        id == "ideaExchange" ? this.selectIdeaExchangeOptions(): "";
        id == "promomats" ? this.selectVVPMOptions(): "";
        id == "common" ? this.selectCommonColumnsOptions():"";
        id == "pie" ? this.selectPIEOptions(): "";
        id == "stp" ? this.selectSTPOptions(): "";
        id  == "modelRepo" ? this.selectModelRepoOptions():"";
      }
    
      var selectAllToggle;
      var gmsaNewsToggle;
      var vvmcToggle;
      var vvqdToggle;
      var commonColumnsToggle;
      var ideaExchangeToggle;
      var vvpmToggle;
      var pieToggle;
      var stpToggle;
      var modelRepoToggle;
      var inputElements:any = document.getElementsByName('export');
      for (var i = 0; inputElements[i]; i++) {
        if (inputElements[i].checked == false) {
          selectAllToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var modelRepoElements:any = document.getElementsByClassName('modelRepo');
      for (var modelRepo = 0; modelRepoElements[modelRepo]; modelRepo++) {
        if (modelRepoElements[modelRepo].checked == false) {
          modelRepoToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var pieElements:any = document.getElementsByClassName('pie');
      for (var pie = 0; pieElements[pie]; pie++) {
        if (pieElements[pie].checked == false) {
          pieToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var stpElements:any = document.getElementsByClassName('stp');
      for (var stp = 0; stpElements[stp]; stp++) {
        if (stpElements[stp].checked == false) {
          stpToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var vvpmElements:any = document.getElementsByClassName('promomats');
      for (var vvpm = 0; vvpmElements[vvpm]; vvpm++) {
        if (vvpmElements[vvpm].checked == false) {
          vvpmToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var ideaExchangeElements:any = document.getElementsByClassName('ideaExchange');
      for (var id1 = 0; ideaExchangeElements[id1]; id1++) {
        if (ideaExchangeElements[id1].checked == false) {
          ideaExchangeToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }
      var commoninputElements:any = document.getElementsByClassName('common');
      for (var i4 = 0; commoninputElements[i4]; i4++) {
        if (commoninputElements[i4].checked == false) {
          commonColumnsToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var gmsanewsinputElements:any = document.getElementsByClassName('gmsanews');
      for (var i1 = 0; gmsanewsinputElements[i1]; i1++) {
        if (gmsanewsinputElements[i1].checked == false) {
          gmsaNewsToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }
      var vvmcinputElements:any = document.getElementsByClassName('vvmc');
      for (var i2 = 0; vvmcinputElements[i2]; i2++) {
        if (vvmcinputElements[i2].checked == false) {
          vvmcToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }

      var vvqdinputElements:any = document.getElementsByClassName('vvqd');
      for (var i3 = 0; vvqdinputElements[i3]; i3++) {
        if (vvqdinputElements[i3].checked == false) {
          vvqdToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }
      // if (selectAllToggle) {
      //   this.document.getElementById("selectAll").checked = false;
      // }
      // else {
      //   this.document.getElementById("selectAll").checked = true;
      // }

      if (modelRepoToggle) {
        this.document.getElementById("modelRepo").checked = false;
      }
      else {
        this.document.getElementById("modelRepo").checked = true;
      }

      if (stpToggle) {
        this.document.getElementById("stp").checked = false;
      }
      else {
        this.document.getElementById("stp").checked = true;
      }
      if (pieToggle) {
        this.document.getElementById("pie").checked = false;
      }
      else {
        this.document.getElementById("pie").checked = true;
      }
      if (vvpmToggle) {
        this.document.getElementById("promomats").checked = false;
      }
      else {
        this.document.getElementById("promomats").checked = true;
      }
      if (ideaExchangeToggle) {
        this.document.getElementById("ideaExchange").checked = false;
      }
      else {
        this.document.getElementById("ideaExchange").checked = true;
      }
      if (gmsaNewsToggle) {
        this.document.getElementById("gmsanews").checked = false;
      }
      else {
        this.document.getElementById("gmsanews").checked = true;
      }
      if (vvmcToggle) {
        this.document.getElementById("vvmc").checked = false;
      }
      else {
        this.document.getElementById("vvmc").checked = true;
      }
      if (vvqdToggle) {
        this.document.getElementById("vvqd").checked = false;
      }
      else {
        this.document.getElementById("vvqd").checked = true;
      }

      if (commonColumnsToggle) {
        this.document.getElementById("common").checked = false;
      }
      else {
        this.document.getElementById("common").checked = true;
      }

      var inputElements = this.document.getElementsByName('export');
      for (var i = 0; inputElements[i]; i++) {
        if (inputElements[i].checked == false) {
          selectAllToggle = true;
          // document.getElementById("selectAll").checked=false;
          //// console.log("made select all to false");
          break;
        }
      }
      if (selectAllToggle) {
        //  // console.log("selectAllToggle", selectAllToggle)
          this.document.getElementById("selectAll").checked = false;
        }
        else {
         // // console.log("selectAllToggle", selectAllToggle)
          this.document.getElementById("selectAll").checked = true;
        }
    }
    enableDisableButton(){
                // console.log("enableDisableButton called");
      var selectAllToggle;
  var gmsaNewsToggle;
  var vvmcToggle;
  var vvqdToggle;
  var commonColumnsToggle;

  var ideaExchangeToggle;
  var vvpmToggle;
  var pieToggle;
  var stpToggle;
  var modelRepoToggle;

  var modelRepoElements:any = document.getElementsByClassName('modelRepo');
  for (var modelRepo = 0; modelRepoElements[modelRepo]; modelRepo++) {
    if (modelRepoElements[modelRepo].checked == false) {
      modelRepoToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  var stpElements:any = document.getElementsByClassName('stp');
  for (var stp = 0; stpElements[stp]; stp++) {
    if (stpElements[stp].checked == false) {
      stpToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  var pieElements:any = document.getElementsByClassName('pie');
  for (var pie = 0; pieElements[pie]; pie++) {
    if (pieElements[pie].checked == false) {
      pieToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  var promomatsElements:any = document.getElementsByClassName('promomats');
  for (var vvpm = 0; promomatsElements[vvpm]; vvpm++) {
    if (promomatsElements[vvpm].checked == false) {
      vvpmToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  var ideaExchangeElements:any = document.getElementsByClassName('ideaExchange');
  for (var id1 = 0; ideaExchangeElements[id1]; id1++) {
    if (ideaExchangeElements[id1].checked == false) {
      ideaExchangeToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }
  
  
  var gmsanewsinputElements:any = document.getElementsByClassName('gmsanews');
  for (var i1 = 0; gmsanewsinputElements[i1]; i1++) {
    if (gmsanewsinputElements[i1].checked == false) {
      gmsaNewsToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  if (gmsaNewsToggle) {
   // // console.log("gmsaNewsToggle", gmsaNewsToggle)
    this.document.getElementById("gmsanews").checked = false;
  }
  else {
   // // console.log("selectAllToggle", selectAllToggle)
    this.document.getElementById("gmsanews").checked = true;
  }

  var vvmcinputElements:any = document.getElementsByClassName('vvmc');
  for (var i2 = 0; vvmcinputElements[i2]; i2++) {
    if (vvmcinputElements[i2].checked == false) {
      vvmcToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  var vvqdinputElements:any = document.getElementsByClassName('vvqd');
  for (var i3 = 0; vvqdinputElements[i3]; i3++) {
    if (vvqdinputElements[i3].checked == false) {
      vvqdToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  var commoninputElements:any = document.getElementsByClassName('common');
  for (var i4 = 0; commoninputElements[i4]; i4++) {
    if (commoninputElements[i4].checked == false) {
      commonColumnsToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }

  if (vvmcToggle) {
    this.document.getElementById("vvmc").checked = false;
  }
  else {
    this.document.getElementById("vvmc").checked = true;
  }
  if (vvqdToggle) {
    this.document.getElementById("vvqd").checked = false;
  }
  else {
    this.document.getElementById("vvqd").checked = true;
  }

  if (commonColumnsToggle) {
    this.document.getElementById("common").checked = false;
  }
  else {
    this.document.getElementById("common").checked = true;
  }
  if (ideaExchangeToggle) {
    // // console.log("gmsaNewsToggle", gmsaNewsToggle)
     this.document.getElementById("ideaExchange").checked = false;
   }
   else {
    // // console.log("selectAllToggle", selectAllToggle)
     this.document.getElementById("ideaExchange").checked = true;
   }
   
   if (vvpmToggle) {
    // // console.log("gmsaNewsToggle", gmsaNewsToggle)
     this.document.getElementById("promomats").checked = false;
   }
   else {
    // // console.log("selectAllToggle", selectAllToggle)
     this.document.getElementById("promomats").checked = true;
   }

   if (pieToggle) {
    // // console.log("gmsaNewsToggle", gmsaNewsToggle)
     this.document.getElementById("pie").checked = false;
   }
   else {
    // // console.log("selectAllToggle", selectAllToggle)
     this.document.getElementById("pie").checked = true;
   }
   if (stpToggle) {
    // // console.log("gmsaNewsToggle", gmsaNewsToggle)
     this.document.getElementById("stp").checked = false;
   }
   else {
    // // console.log("selectAllToggle", selectAllToggle)
     this.document.getElementById("stp").checked = true;
   }

   if (modelRepoToggle) {
    // // console.log("gmsaNewsToggle", gmsaNewsToggle)
     this.document.getElementById("modelRepo").checked = false;
   }
   else {
    // // console.log("selectAllToggle", selectAllToggle)
     this.document.getElementById("modelRepo").checked = true;
   }

 

  // if(gmsaNewsToggle && commonColumnsToggle && vvqdToggle && vvmcToggle ){
  //   this.document.getElementById("selectAll").checked = true;
  // }
  // else{
  //   this.document.getElementById("selectAll").checked = false;
  // }

  var inputElements = this.document.getElementsByName('export');
  for (var i = 0; inputElements[i]; i++) {
    if (inputElements[i].checked == false) {
      selectAllToggle = true;
      // document.getElementById("selectAll").checked=false;
      //// console.log("made select all to false");
      break;
    }
  }
  if (selectAllToggle) {
    //  // console.log("selectAllToggle", selectAllToggle)
      this.document.getElementById("selectAll").checked = false;
    }
    else {
     // // console.log("selectAllToggle", selectAllToggle)
      this.document.getElementById("selectAll").checked = true;
    }

    }

    userOptions(){

    }

    selectCommonColumnsOptions(){
      var isChecked = this.document.getElementById("common")?.checked;
      var inputElements = this.document.getElementsByClassName('common');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
          
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
        //this.document.getElementById("selectAll").checked = false;
      }
      if(this.checkIfSellectAllSelected()){
        this.document.getElementById("selectAll").checked = true;
       }
       else{
        this.document.getElementById("selectAll").checked = false;
       }

    }
    selectGMSANewsOptions(){
      var isChecked = this.document.getElementById("gmsanews")?.checked;
      var inputElements = this.document.getElementsByClassName('gmsanews');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
        //this.document.getElementById("selectAll").checked = false;
      }
      if(this.checkIfSellectAllSelected()){
        this.document.getElementById("selectAll").checked = true;
       }
       else{
        this.document.getElementById("selectAll").checked = false;
       }


    }

    selectVVMCOptions(){
      var isChecked = this.document.getElementById("vvmc")?.checked;
      var inputElements = this.document.getElementsByClassName('vvmc');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
        //this.document.getElementById("selectAll").checked = false;
      }
      if(this.checkIfSellectAllSelected()){
        this.document.getElementById("selectAll").checked = true;
       }
       else{
        this.document.getElementById("selectAll").checked = false;
       }

    }

    selectVVQDOptions(){
      var isChecked = this.document.getElementById("vvqd")?.checked;
      var inputElements = this.document.getElementsByClassName('vvqd');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }
      //this.document.getElementById("selectAll").checked = false;
             if(this.checkIfSellectAllSelected()){
              this.document.getElementById("selectAll").checked = true;
             }
             else{
              this.document.getElementById("selectAll").checked = false;
             }
    }

    selectIdeaExchangeOptions(){
      var isChecked = this.document.getElementById("ideaExchange")?.checked;
      var inputElements = this.document.getElementsByClassName('ideaExchange');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }
      //this.document.getElementById("selectAll").checked = false;
             if(this.checkIfSellectAllSelected()){
              this.document.getElementById("selectAll").checked = true;
             }
             else{
              this.document.getElementById("selectAll").checked = false;
             }
    }

    selectVVPMOptions(){
      var isChecked = this.document.getElementById("promomats")?.checked;
      var inputElements = this.document.getElementsByClassName('promomats');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }
      //this.document.getElementById("selectAll").checked = false;
             if(this.checkIfSellectAllSelected()){
              this.document.getElementById("selectAll").checked = true;
             }
             else{
              this.document.getElementById("selectAll").checked = false;
             }
    }

    selectPIEOptions(){

      var isChecked = this.document.getElementById("pie")?.checked;
      var inputElements = this.document.getElementsByClassName('pie');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }
      //this.document.getElementById("selectAll").checked = false;
             if(this.checkIfSellectAllSelected()){
              this.document.getElementById("selectAll").checked = true;
             }
             else{
              this.document.getElementById("selectAll").checked = false;
             }
    }

    selectSTPOptions(){

      var isChecked = this.document.getElementById("stp")?.checked;
      var inputElements = this.document.getElementsByClassName('stp');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }
      //this.document.getElementById("selectAll").checked = false;
             if(this.checkIfSellectAllSelected()){
              this.document.getElementById("selectAll").checked = true;
             }
             else{
              this.document.getElementById("selectAll").checked = false;
             }
    }

    selectModelRepoOptions(){

      var isChecked = this.document.getElementById("modelRepo")?.checked;
      var inputElements = this.document.getElementsByClassName('modelRepo');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
       // this.document.getElementById("selectAll").checked = true;
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }
      //this.document.getElementById("selectAll").checked = false;
             if(this.checkIfSellectAllSelected()){
              this.document.getElementById("selectAll").checked = true;
             }
             else{
              this.document.getElementById("selectAll").checked = false;
             }
    }

    selectAllOptions(){

      var isChecked = this.document.getElementById("selectAll")?.checked;
      var inputElements = this.document.getElementsByName('export');
      if (isChecked) {
        for (var i = 0; inputElements[i];i++) {
          inputElements[i].checked = true;
          // document.getElementById("exportToExcelBtn").disabled= false;
        }
      }
      else {
        for (var z = 0; inputElements[z];z++) {
          inputElements[z].checked = false;
          // document.getElementById("exportToExcelBtn").disabled= true;
        }
      }

    }

    checkIfSellectAllSelected(){
      var isCheckedVVQD = this.document.getElementById("vvqd")?.checked;
      var isCheckedGMSANEWS = this.document.getElementById("gmsanews")?.checked;
      var isCheckedVVMC = this.document.getElementById("vvmc")?.checked;
      var isCheckedCOMMON = this.document.getElementById("common")?.checked; 
      var isCheckedIdeaExchange = this.document.getElementById("ideaExchange")?.checked;
      var isCheckedVVPM = this.document.getElementById("promomats")?.checked;
      var isCheckedPIE = this.document.getElementById("pie")?.checked;
      var isCheckedSTP = this.document.getElementById("stp")?.checked;
      var isCheckedModelRepo = this.document.getElementById("modelRepo")?.checked;

       if(isCheckedVVQD &&  isCheckedGMSANEWS &&  isCheckedVVMC && isCheckedCOMMON && isCheckedIdeaExchange && isCheckedVVPM && isCheckedPIE && isCheckedSTP && isCheckedModelRepo){
           return true;
       }
       else{
        return false;
       }
    }

}