<div *ngIf="exportToExcelPopUp"
class="overlay"
id="overlay" (click)="closeMainTab()">  </div>

    <!-- The content of the pop-up -->

    
    <div *ngIf="exportToExcelPopUp"
class="popup-content" id="popup-ipad">

<div><span  style="display: inline;font-size: 16px;font-weight: 700; font-family: Invention;">Choose columns to export</span></div>
<!-- <div style="display: flex; justify-content: space-between;"><span style=" display: inline; ">Choose columns to export</span> <span (click)="closeMainTab()"  style=" margin-left:467px; font-weight: 400; font-size: 1.0rem;cursor: pointer;color:teal;">X</span></div> -->

     <!-- <button style="position: absolute;  right:565px" (click)="closeNotifications()">Close</button> -->
     <!-- <div style="display: flex; justify-content: space-between;"><span style=" display: inline; ">Choose columns to export</span> <span (click)="closeMainTab()"  style=" margin-left:467px; font-weight: 400; font-size: 1.0rem;cursor: pointer;color:teal;">X</span></div> -->
            <br>
            <div style="display: flex; flex-basis:100% ;">
          <input style="display: inline" (click)="selectAllOptions()"  type="checkbox" id="selectAll" value="selectAll" checked> 	
          <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('selectAll')">Select All</label> 	 
          </div>
          <br>
          <form>
            
              
            <!-- <h4 style="font-weight: 700;">Common Columns</h4> -->
            <h4 style="font-weight: 700; display: inline; cursor: pointer;" (click)="toggleCollapse('commonColumns')">  <span  
              class="arrow"
              [class.collapsed]="exportToExcelCollapsibles.commonColumns"></span>Common Columns</h4>

            <div
            [hidden]="exportToExcelCollapsibles.commonColumns"
            class="collapsible-content">
            <div style="display: flex; flex-basis:100% ;">
              <input style="display: inline;" (click)="selectCommonColumnsOptions()"  name="export" type="checkbox" id="common" value="common" checked> 	
               <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('common')">Select All</label> 	
            </div> <br>
           <div class="flexdirection">
             <div class="flexbasis">
              <input type="checkbox" id="title"  (click)="enableDisableButton()" name="export" class="common" value="title" checked>
              <label class="sidebyside" (click)="checkBoxHandler('title')">Document Name / Title</label>
             </div>
             <div class="flexbasis">
              <input type="checkbox" id="printableUri"  (click)="enableDisableButton()" name="export" class="common" value="printableUri" checked>
              <label class="sidebyside" (click)="checkBoxHandler('printableUri')">Source Link</label>
             </div>
           </div>  

             <div class="flexdirection">
              <div class="flexbasis">
                <input type="checkbox" id="contentdocumentnumber"  (click)="enableDisableButton()" class="common" name="export" value="contentdocumentnumber" checked>
                <label class="sidebyside" (click)="checkBoxHandler('contentdocumentnumber')">Document Number</label>
              </div>
              <div class="flexbasis">
                <input type="checkbox" id="contentitemstatus"  (click)="enableDisableButton()" class="common"  name="export" value="contentitemstatus" checked>
                <label class="sidebyside" (click)="checkBoxHandler('contentitemstatus')">Status</label> </div>
            
             </div> 

             <div class="flexdirection">
               <div  class="flexbasis">
                <input type="checkbox" id="qddocumenttype" class="common" (click)="enableDisableButton()" name="export" value="qddocumenttype" checked>
                <label class="sidebyside" (click)="checkBoxHandler('qddocumenttype')">Document Type</label>
               </div>
               
                 <div  class="flexbasis">
                  <input type="checkbox" id="qddocumentsubtype" class="common" (click)="enableDisableButton()"  name="export" value="qddocumentsubtype" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('qddocumentsubtype')">Document Sub Type</label>
                 </div>
              
                 </div>

                 <div class="flexdirection">
                  <div  class="flexbasis">
                    <input type="checkbox" id="contenttherapeuticarea"  (click)="enableDisableButton()" class="common" name="export" value="contenttherapeuticarea" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('contenttherapeuticarea')">Therapeutic Area</label> 
                  </div>
                  <div  class="flexbasis">
                    <input type="checkbox" id="qdeffectivedate"  (click)="enableDisableButton()" class="common" name="export" value="qdeffectivedate" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('qdeffectivedate')">Effective Date</label>
                  </div>
                 
                
               </div> 
               <div class="flexdirection">
                          <div class="flexbasis">
                                                          
                <input type="checkbox" id="contentversion" class="common" (click)="enableDisableButton()" name="export" value="contentversion" checked>
                <label class="sidebyside" (click)="checkBoxHandler('contentversion')">Version</label>
                          
                          </div>
               </div>

               </div>

             
            <h4 style="font-weight: 700; cursor: pointer;" (click)="toggleCollapse('gmsaNews')"> <span  
              class="arrow"
              [class.collapsed]="exportToExcelCollapsibles.gmsaNews"></span> GMSA News</h4>
            <!-- <label id="info"></label> -->

            <div
            [hidden]="exportToExcelCollapsibles.gmsaNews"
            class="collapsible-content">
             <div style="display: flex; flex-basis:100%;">
              <input style="display: inline;" (click)="selectGMSANewsOptions()"  name="export" type="checkbox" id="gmsanews" value="gmsanews" checked> 	
              <label  style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('gmsanews')">Select All</label> 	
             </div> <br>
           
              
                 <div class="flexdirection">
                  <div class="flexbasis">
                    <input type="checkbox" id="contentspcategoryannouncements"  (click)="enableDisableButton()" class="gmsanews" name="export" value="contentspcategoryannouncements" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('contentspcategoryannouncements')">Announcements</label>
                  </div>
                  <div class="flexbasis">
                    <input type="checkbox" id="contentspcategoryfunctionalareas"  (click)="enableDisableButton()" class="gmsanews" name="export" value="contentspcategoryfunctionalareas" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('contentspcategoryfunctionalareas')">Functional Areas</label>    
                  </div>
                
              
                   </div> 
                   <div class="flexdirection"> <div class="flexbasis">
                   <input type="checkbox" id="contentspcategorygmacapabilities"  (click)="enableDisableButton()" class="gmsanews" name="export" value="contentspcategorygmacapabilities" checked>
                   <label class="sidebyside" (click)="checkBoxHandler('contentspcategorygmacapabilities')">GMA Capabilities</label>
                  </div>
                  <div class="flexbasis">

                 
                   <input type="checkbox" id="contentspcategoryleadermessages"  (click)="enableDisableButton()" class="gmsanews" name="export" value="contentspcategoryleadermessages" checked>
                   <label class="sidebyside" (click)="checkBoxHandler('contentspcategoryleadermessages')">Leader Messages</label>    
                  </div>   
                  </div> 
                     <div class="flexdirection"> <div class="flexbasis">
                      <input type="checkbox" id="contentspcategoryspecialtopics"  (click)="enableDisableButton()" name="export" class="gmsanews" value="contentspcategoryspecialtopics" checked>
                      <label class="sidebyside" (click)="checkBoxHandler('contentspcategoryspecialtopics')">Special Topics</label>  
                         </div><div class="flexbasis">
                     <input type="checkbox" id="gmsapublisheddate"  (click)="enableDisableButton()" class="gmsanews" name="export" value="gmsapublisheddate" checked>
                     <label class="sidebyside" (click)="checkBoxHandler('gmsapublisheddate')">Published  Date</label> 
                    </div>
                   </div>  
                        <div class="flexdirection">
                          <div class="flexbasis">
                        <input type="checkbox" id="gmsaregion"  (click)="enableDisableButton()" name="export" class="gmsanews" value="gmsaregion" checked>
                          <label class="sidebyside" (click)="checkBoxHandler('gmsaregion')">Region</label>
                          </div>
                          <div class="flexbasis">
                            
               <input type="checkbox" id="author"  (click)="enableDisableButton()" name="export"  class="gmsanews" value="author" checked>
               <label class="sidebyside" (click)="checkBoxHandler('author')">Author</label>
                          </div>
                            </div>

                            
                          
                
                          </div>
                      
       
                           
           <h4 style="font-weight: 700;  cursor: pointer;"  (click)="toggleCollapse('vvmc')" ><span 
            class="arrow"
            [class.collapsed]="exportToExcelCollapsibles.vvmc"></span>Veeva Vault MedComms</h4>
            <div
            [hidden]="exportToExcelCollapsibles.vvmc"
            class="collapsible-content">
            <div style="display: flex; flex-basis:100%;">
           <input style="display: inline;" (click)="selectVVMCOptions()"  name="export" type="checkbox" id="vvmc" value="vvmc" checked> 	
          <label style="font-size:14px; font-weight: 400;"  (click)="checkBoxHandler('vvmc')">Select All</label> 	
        </div>
        <br>
             
            
            
            <div class="flexdirection">
              <div class="flexbasis">

             
              <input type="checkbox" id="description" (click)="enableDisableButton()" name="export" class="vvmc" value="description" checked>
              <label class="sidebyside" (click)="checkBoxHandler('description')">Description</label>
              </div> <div class="flexbasis">
                <input type="checkbox" id="contentintendeduse" (click)="enableDisableButton()"  name="export" class="vvmc" value="contentintendeduse" checked>
                <label class="sidebyside" (click)="checkBoxHandler('contentintendeduse')">Intended Use</label> </div>
              </div> 
            
               
            
            <div class="flexdirection">
              <div class="flexbasis">
            <input type="checkbox" id="contentproductname"  (click)="enableDisableButton()" name="export" class="vvmc" value="contentproductname" checked>
            <label class="sidebyside" (click)="checkBoxHandler('contentproductname')">Product</label>
               </div> <div class="flexbasis">

               
            <input type="checkbox" id="vvmctherapeuticareaveap" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmctherapeuticareaveap" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmctherapeuticareaveap')">Therapeutic Area (VEAP)</label> </div>
            
            </div>  
            
            <div class="flexdirection"> 
              <div class="flexbasis">

              
            <input type="checkbox" id="language" (click)="enableDisableButton()"  name="export" class="vvmc" value="language" checked>
            <label class="sidebyside" (click)="checkBoxHandler('language')">Language</label> </div>

            <div class="flexbasis">
            
            <input type="checkbox" id="contentproactive"  (click)="enableDisableButton()" name="export" class="vvmc" value="contentproactive" checked>
            <label class="sidebyside" (click)="checkBoxHandler('contentproactive')">Proactive</label> </div>
            </div>  
            <div class="flexdirection">
              <div class="flexbasis">
            <input type="checkbox" id="contentinclm" (click)="enableDisableButton()"  name="export" class="vvmc" value="contentinclm" checked>
            <label class="sidebyside" (click)="checkBoxHandler('contentinclm')">Content In CRM</label>
            </div>
            <div class="flexbasis">
            
            <input type="checkbox" id="contentinirms" (click)="enableDisableButton()"  name="export" class="vvmc" value="contentinirms" checked>
            <label class="sidebyside" (click)="checkBoxHandler('contentinirms')">Content In MI</label> </div>
            </div>  
            <div class="flexdirection">
              <div class="flexbasis">
            <input type="checkbox" id="gmsaapprovaldate" (click)="enableDisableButton()" name="export" class="vvmc" value="gmsaapprovaldate" checked>
            <label class="sidebyside" (click)="checkBoxHandler('gmsaapprovaldate')">Approval Date</label>
             </div> <div class="flexbasis">
            <input type="checkbox" id="contentsourceid" (click)="enableDisableButton()" name="export" class="vvmc" value="contentsourceid" checked>
            <label class="sidebyside" (click)="checkBoxHandler('contentsourceid')">Document ID</label> </div>
            </div>  
            
           
            
            <div class="flexdirection"> <div class="flexbasis">
            <input type="checkbox" id="vvmcgvdveapid" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmcgvdveapid" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmcgvdveapid')">REV/OPS ID</label> </div>
            <div class="flexbasis">
            
            <input type="checkbox" id="vvmcgvdkeycontact" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmcgvdkeycontact" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmcgvdkeycontact')">Key Contact</label> </div>
            </div>  
            
            <div class="flexdirection"> <div class="flexbasis">
            <input type="checkbox" id="vvmcgvddepartment" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmcgvddepartment" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmcgvddepartment')">Department (VEAP)</label> </div>

            <div class="flexbasis">
            
            <input type="checkbox" id="vvmc_core_model_type" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmc_core_model_type" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmc_core_model_type')">Model Type</label> </div>
            </div>  
            
            <div class="flexdirection"> <div class="flexbasis">
            <input type="checkbox" id="vvmc_core_dcr_approval_date" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmc_core_dcr_approval_date" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmc_core_dcr_approval_date')">DRC Approval Date</label> </div> 
            <div class="flexbasis">
            <input type="checkbox" id="vvmc_core_activity_type" (click)="enableDisableButton()" class="vvmc" name="export" value="vvmc_core_activity_type" checked>
            <label class="sidebyside" (click)="checkBoxHandler('vvmc_core_activity_type')">Activity Type</label> </div>
            </div> 
            
            <div class="flexdirection"> <div class="flexbasis">
            <input type="checkbox" id="payerregion" (click)="enableDisableButton()" name="export" class="vvmc" value="payerregion" checked>
            <label class="sidebyside" (click)="checkBoxHandler('payerregion')">Payor Region</label> </div> <div class="flexbasis">
            <input type="checkbox" id="payertopic" (click)="enableDisableButton()" name="export" class="vvmc" value="payertopic" checked>
            <label class="sidebyside" (click)="checkBoxHandler('payertopic')">Payor Topic</label> </div>
            </div>

           
            <div class="flexdirection">
            <div class="flexbasis">    
              <input type="checkbox" id="payer" (click)="enableDisableButton()" name="export" class="vvmc" value="payer" checked>
              <label class="sidebyside" (click)="checkBoxHandler('payer')">Payor Use Only</label>
              </div>
              <div class="flexbasis">
                <input type="checkbox" id="gmsacountry"  (click)="enableDisableButton()" name="export" class="vvmc" value="gmsacountry" checked>
                <label class="sidebyside" (click)="checkBoxHandler('gmsacountry')">Country</label> </div>
            
            </div>

              <div class="flexdirection"> 
                <div class="flexbasis">
                <input type="checkbox" id="niscompletiondate"  (click)="enableDisableButton()" name="export" class="vvmc" value="niscompletiondate" checked>
                <label class="sidebyside" (click)="checkBoxHandler('niscompletiondate')">Planned/Actual Report Completion Date</label> </div>
    
                <div class="flexbasis">
                
                  <input type="checkbox" id="veapmkvnumber"  (click)="enableDisableButton()" name="export" class="vvmc" value="veapmkvnumber" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('veapmkvnumber')">MK/V Number</label> 
               
              </div>
                </div>

                <div class="flexdirection"> 
                  <div class="flexbasis">
                    <input type="checkbox" id="nisapprovaldate" (click)="enableDisableButton()"  name="export" class="vvmc" value="nisapprovaldate" checked>
                <label class="sidebyside" (click)="checkBoxHandler('nisapprovaldate')">Planned/Actual Protocol Approval Date</label>
                 
                 </div>
      
                  <div class="flexbasis">
                  
                    <input type="checkbox" id="veapproduct" (click)="enableDisableButton()"  name="export" class="vvmc" value="veapproduct" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('veapproduct')">Name of Product (REV/OPS)</label> 

                  
                </div>
                  </div>

                  <div class="flexdirection"> 
                    <div class="flexbasis">
                       <input type="checkbox" id="nisactivitystatus"  (click)="enableDisableButton()" name="export" class="vvmc" value="nisactivitystatus" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('nisactivitystatus')">Activity status (REV/OPS)</label>
                   
                  </div>
        
                    <div class="flexbasis">
                    
                      <input type="checkbox" id="veapcontact"  (click)="enableDisableButton()" name="export" class="vvmc" value="veapcontact" checked>
                          <label class="sidebyside" (click)="checkBoxHandler('veapcontact')">Steward/Contact (REV/OPS)</label>
                    
                  
                  </div>
                    </div>

                    <div class="flexdirection"> 
                      <div class="flexbasis">
                   <input type="checkbox" id="veapactivitytype" (click)="enableDisableButton()"  name="export" class="vvmc" value="veapactivitytype" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('veapactivitytype')">Activity type (REV/OPS)</label> 
                     
                     </div>
          
                      <div class="flexbasis">
                      
                        <input type="checkbox" id="veapstudyobjective" (click)="enableDisableButton()"  name="export" class="vvmc" value="veapstudyobjective" checked>
                        <label class="sidebyside" (click)="checkBoxHandler('veapstudyobjective')">Study Objective</label> 

                      
                      </div>
                      </div>
                      <div class="flexdirection"> 
                        <div class="flexbasis">
                           <input type="checkbox" id="veapcountry"  (click)="enableDisableButton()" name="export" class="vvmc" value="veapcountry" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('veapcountry')">Country (REV/OPS)</label>
                       
                      </div>
            
                        <div class="flexbasis">
                          <input type="checkbox" id="veaptherapeuticarea"  (click)="enableDisableButton()" name="export" class="vvmc" value="veaptherapeuticarea" checked>
                          <label class="sidebyside" (click)="checkBoxHandler('veaptherapeuticarea')">Therapeutic area (REV/OPS)</label> 
                       
                      </div>
                        </div>

                        <div class="flexdirection"> 
                          <div class="flexbasis">
                            <input type="checkbox" id="veapcustomerbusline" (click)="enableDisableButton()"  name="export" class="vvmc" value="veapcustomerbusline" checked>
                    <label class="sidebyside" (click)="checkBoxHandler('veapcustomerbusline')">Customer Business Line</label> 
                          
                        </div>
                          <div class="flexbasis">
                           
                            <input type="checkbox" id="veapindication"  (click)="enableDisableButton()" name="export" class="vvmc" value="veapindication" checked>
                            <label class="sidebyside" (click)="checkBoxHandler('veapindication')">Indication/Disease (REV/OPS)</label>
                                 
                          
                          </div>
                        </div>

                          <div class="flexdirection"> 
                           
                            <div class="flexbasis">
                              <input type="checkbox" id="veap_obj_id"  (click)="enableDisableButton()" name="export" class="vvmc" value="veap_obj_id" checked>
                              <label class="sidebyside" (click)="checkBoxHandler('veap_obj_id')">REV/OPS ID</label>
                            
                          </div>
                          <div class="flexbasis">
                             <input type="checkbox" id="veapisldg" (click)="enableDisableButton()"  name="export" class="vvmc" value="veapisldg" checked>
                            <label class="sidebyside" (click)="checkBoxHandler('veapisldg')">Is LDG? (REV/OPS)</label> 
                        </div>
                       </div>
                       <div class="flexdirection"> 
                           
                        <div class="flexbasis">
                          <input type="checkbox" id="gmsa_sub_therapeutic_area" (click)="enableDisableButton()" name="export" class="vvmc" value="gmsa_sub_therapeutic_area" checked>
                          <label class="sidebyside" (click)="checkBoxHandler('gmsa_sub_therapeutic_area')">Sub Therapeutic Area</label>
      
                      </div>
                
                   </div>
                       </div>
                           
                     
                              
  

             <h4 style="font-weight: 700;  cursor: pointer;" (click)="toggleCollapse('vvqd')" > <span 
              class="arrow"
              [class.collapsed]="exportToExcelCollapsibles.vvqd"></span>Veeva Vault QualityDocs</h4>
             
              <div
              [hidden]="exportToExcelCollapsibles.vvqd"
              class="collapsible-content">
              <div style="display: flex; flex-basis:100%;">
              
             <input style="display: inline" (click)="selectVVQDOptions()"  name="export" type="checkbox" id="vvqd" value="vvqd" checked> 	
          <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('vvqd')">Select All</label> 	 </div>
          <br>
          
                 <div class="flexdirection"> <div class="flexbasis">
                <input type="checkbox" id="mrlclassification" class="vvqd"  (click)="enableDisableButton()" name="export" value="mrlclassification" checked>
                <label class="sidebyside" (click)="checkBoxHandler('mrlclassification')">MRL Classification</label>
                </div><div class="flexbasis">
                <input type="checkbox" id="qdsensitivityclassification" class="vvqd" (click)="enableDisableButton()"  name="export" value="qdsensitivityclassification" checked>
                <label class="sidebyside" (click)="checkBoxHandler('qdsensitivityclassification')">Sensitivity Classification</label> </div>
                </div>  
                
                <div class="flexdirection"> <div class="flexbasis">
                <input type="checkbox" id="impactedcountry" class="vvqd"  (click)="enableDisableButton()" name="export" value="impactedcountry" checked>
                <label class="sidebyside" (click)="checkBoxHandler('impactedcountry')">Impacted Countries/Regions</label>
                </div> <div class="flexbasis">
                  <input type="checkbox" id="qdimpacteddepartment" class="vvqd" (click)="enableDisableButton()"  name="export" value="qdimpacteddepartment" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('qdimpacteddepartment')">Impacted Department</label>
 </div>
                </div>  
                
                

                <div class="flexdirection"> <div class="flexbasis">
                  <input type="checkbox" id="qddocumentversionowner" class="vvqd"  (click)="enableDisableButton()" name="export" value="qddocumentversionowner" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('qddocumentversionowner')">Version Owner</label> </div>
                  
                  <div class="flexbasis">
                  <input type="checkbox" id="contentdocumentowner" class="vvqd"  (click)="enableDisableButton()" name="export" value="contentdocumentowner" checked>
                  <label class="sidebyside" (click)="checkBoxHandler('contentdocumentowner')">Document Owner</label></div>
                  </div> 

                    <div class="flexdirection"> <div class="flexbasis">
                      <input type="checkbox" id="qdfinaldate" class="vvqd"  (click)="enableDisableButton()" name="export" value="qdfinaldate" checked>
                      <label class="sidebyside" (click)="checkBoxHandler('qdfinaldate')">Final Date</label> </div>
                      <!-- </div> <div class="flexbasis">
                      <input type="checkbox" id="contentitemstatus" class="vvqd" (click)="enableDisableButton()"  name="export" value="contentitemstatus" checked>
                      <label class="sidebyside" (click)="checkBoxHandler('contentitemstatus')">Status</label> </div>  -->
                      <div class="flexbasis">
                        <input type="checkbox" id="syssource" class="vvqd" (click)="enableDisableButton()"  name="export" value="syssource" checked>
                        <label class="sidebyside" (click)="checkBoxHandler('syssource')">Source</label>     
                      </div>
                      
                      </div> 
                      <div class="flexdirection">
                        <div class="flexbasis">
                          <input type="checkbox" id="qdimpacteddepartment" class="vvqd" (click)="enableDisableButton()"  name="export" value="qdimpacteddepartment" checked>
                          <label class="sidebyside" (click)="checkBoxHandler('qdimpacteddepartment')">Impacted Department</label>
                          
                        
                          </div> </div> </div>

                          <h4 style="font-weight: 700; cursor: pointer;" (click)="toggleCollapse('ideaexchange')" ><span 
                            class="arrow"
                            [class.collapsed]="exportToExcelCollapsibles.ideaexchange"></span>Idea Exchange</h4>
                            <div
                            [hidden]="exportToExcelCollapsibles.ideaexchange"
                            class="collapsible-content">
                            <div style="display: flex; flex-basis:100%;">
                           
                          <input style="display: inline" (click)="selectIdeaExchangeOptions()"  name="export" type="checkbox" id="ideaExchange" value="ideaExchange" checked> 	
                       <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('ideaExchange')">Select All</label> 	 </div>
                       <br>
                       
                              <div class="flexdirection"> <div class="flexbasis">
                             <input type="checkbox" id="submitter" class="ideaExchange"  (click)="enableDisableButton()" name="export" value="submitter" checked>
                             <label class="sidebyside" (click)="checkBoxHandler('submitter')">Submitter</label>
                             </div>
                             <div class="flexbasis">
                              <input type="checkbox" id="category" class="ideaExchange" (click)="enableDisableButton()"  name="export" value="category" checked>
                              <label class="sidebyside" (click)="checkBoxHandler('category')">Category</label>

                              
                              </div>
                             </div>  

                             <div class="flexdirection"> <div class="flexbasis">
                              <input type="checkbox" id="submission_date" class="ideaExchange"  (click)="enableDisableButton()" name="export" value="submission_date" checked>
                                <label class="sidebyside" (click)="checkBoxHandler('submission_date')">Submission Date</label>
                              
                              </div><div class="flexbasis">
                                <input type="checkbox" id="event" class="ideaExchange" (click)="enableDisableButton()"  name="export" value="event" checked>
                                <label class="sidebyside" (click)="checkBoxHandler('event')">Event</label>
                                 </div>
                              </div> 

                              <div class="flexdirection"> <div class="flexbasis">
                                <input type="checkbox" id="region" class="ideaExchange"  (click)="enableDisableButton()" name="export" value="region" checked>
                                <label class="sidebyside" (click)="checkBoxHandler('region')">Region</label>
                                </div><div class="flexbasis">
                                  <input type="checkbox" id="therapeutic_area" class="ideaExchange"  (click)="enableDisableButton()" name="export" value="therapeutic_area" checked>
                                  <label class="sidebyside" (click)="checkBoxHandler('therapeutic_area')">Therapeutic Area(s)</label> </div>
                                </div> </div>

                                <h4 style="font-weight: 700; cursor: pointer;" (click)="toggleCollapse('promomats')" ><span 
                                  class="arrow"
                                  [class.collapsed]="exportToExcelCollapsibles.promomats"></span>Market Access (VVPM)</h4>
                                  <div
                                  [hidden]="exportToExcelCollapsibles.promomats"
                                  class="collapsible-content">
                                  <div style="display: flex; flex-basis:100%;">
                                 
                                <input style="display: inline" (click)="selectVVPMOptions()"  name="export" type="checkbox" id="promomats" value="promomats" checked> 	
                             <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('promomats')">Select All</label> 	 </div>
                             <br>
                             
                                    <div class="flexdirection"> <div class="flexbasis">
                                   <input type="checkbox" id="gmsavvpmclassification" class="promomats"  (click)="enableDisableButton()" name="export" value="gmsavvpmclassification" checked>
                                   <label class="sidebyside" (click)="checkBoxHandler('gmsavvpmclassification')">Classification</label>
                                   </div>
                                   <div class="flexbasis">
                                    <input type="checkbox" id="gmsavvpmresourcetype" class="promomats" (click)="enableDisableButton()"  name="export" value="gmsavvpmresourcetype" checked>
                                    <label class="sidebyside" (click)="checkBoxHandler('gmsavvpmresourcetype')">Resource Type</label>
      
                                    
                                    </div>
                                   </div>  

                                   <div class="flexdirection"> <div class="flexbasis">
                                    <input type="checkbox" id="approvaldatedistribution" class="promomats"  (click)="enableDisableButton()" name="export" value="approvaldatedistribution" checked>
                                    <label class="sidebyside" (click)="checkBoxHandler('approvaldatedistribution')">Approval for Distribution Date</label>
                                    </div>
                                    <div class="flexbasis">
                                      <input type="checkbox" id="steward" class="promomats" (click)="enableDisableButton()"  name="export" value="steward" checked>
                                      <label class="sidebyside" (click)="checkBoxHandler('steward')">Steward/Contact</label>
        
                                      
                                      </div>
                                    
                                    </div> 
      
                                  
      
                                    </div>

                                    <h4 style="font-weight: 700; cursor: pointer;" (click)="toggleCollapse('pie')" ><span 
                                      class="arrow"
                                      [class.collapsed]="exportToExcelCollapsibles.pie"></span>PIE</h4>

                                      <div
                                      [hidden]="exportToExcelCollapsibles.pie"
                                      class="collapsible-content">
                                      <div style="display: flex; flex-basis:100%;">
                                     
                                    <input style="display: inline" (click)="selectPIEOptions()"  name="export" type="checkbox" id="pie" value="pie" checked> 	
                                 <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('pie')">Select All</label> 	 </div>
                                 <br>
                                 
                                        <div class="flexdirection"> <div class="flexbasis">
                                       <input type="checkbox" id="pie_disease_type" class="pie"  (click)="enableDisableButton()" name="export" value="pie_disease_type" checked>
                                       <label class="sidebyside" (click)="checkBoxHandler('pie_disease_type')">Tumor or Disease</label>
                                       </div>
                                       <div class="flexbasis">
                                        <input type="checkbox" id="pie_type_patient_engagement" class="pie" (click)="enableDisableButton()"  name="export" value="pie_type_patient_engagement" checked>
                                        <label class="sidebyside" (click)="checkBoxHandler('pie_type_patient_engagement')">Type of Engagement</label>
                                        
                                        </div>
                                       </div>  

                                       <div class="flexdirection"> <div class="flexbasis">
                                        <input type="checkbox" id="pie_author" class="pie"  (click)="enableDisableButton()" name="export" value="pie_author" checked>
                                        <label class="sidebyside" (click)="checkBoxHandler('pie_author')">Steward</label>
                                        </div>
                                        <div class="flexbasis">
                                         <input type="checkbox" id="pie_region" class="pie" (click)="enableDisableButton()"  name="export" value="pie_region" checked>
                                         <label class="sidebyside" (click)="checkBoxHandler('pie_region')">Region</label>
                                         
                                         </div>
                                        </div> 

                                        <div class="flexdirection"> <div class="flexbasis">
                                          <input type="checkbox" id="pie_date" class="pie"  (click)="enableDisableButton()" name="export" value="pie_date" checked>
                                          <label class="sidebyside" (click)="checkBoxHandler('pie_date')">Year</label>
                                          </div>
                                          <div class="flexbasis">
                                           <input type="checkbox" id="pie_clinicaltrial" class="pie" (click)="enableDisableButton()"  name="export" value="pie_clinicaltrial" checked>
                                           <label class="sidebyside" (click)="checkBoxHandler('pie_clinicaltrial')">Clinical Trials</label>
                                           
                                           </div>
                                          </div> 

                                          <div class="flexdirection"> <div class="flexbasis">
                                            <input type="checkbox" id="pie_access" class="pie"  (click)="enableDisableButton()" name="export" value="pie_access" checked>
                                            <label class="sidebyside" (click)="checkBoxHandler('pie_access')">Access</label>
                                            </div>
                                            <div class="flexbasis">
                                             <input type="checkbox" id="pie_patientexperience" class="pie" (click)="enableDisableButton()"  name="export" value="pie_patientexperience" checked>
                                             <label class="sidebyside" (click)="checkBoxHandler('pie_patientexperience')">Patient Experience</label>
                                             
                                             </div>
                                            </div> 

                                            <div class="flexdirection"> <div class="flexbasis">
                                              <input type="checkbox" id="pie_patientfeedback" class="pie"  (click)="enableDisableButton()" name="export" value="pie_patientfeedback" checked>
                                              <label class="sidebyside" (click)="checkBoxHandler('pie_patientfeedback')">Patient Feedback</label>
                                              </div>
                                              
                                              </div> 
    
          
                                      
          
                                        </div>

                                        <h4 style="font-weight: 700; cursor: pointer;" (click)="toggleCollapse('stp')" ><span 
                                          class="arrow"
                                          [class.collapsed]="exportToExcelCollapsibles.stp"></span>Strategic Partnerships</h4>
    
                                          <div
                                          [hidden]="exportToExcelCollapsibles.stp"
                                          class="collapsible-content">
                                          <div style="display: flex; flex-basis:100%;">
                                         
                                        <input style="display: inline" (click)="selectSTPOptions()"  name="export" type="checkbox" id="stp" value="stp" checked> 	
                                     <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('stp')">Select All</label> 	 </div>
                                     <br>
                                     
                                            <div class="flexdirection"> <div class="flexbasis">
                                           <input type="checkbox" id="stp_title" class="stp"  (click)="enableDisableButton()" name="export" value="stp_title" checked>
                                           <label class="sidebyside" (click)="checkBoxHandler('stp_title')">Title</label>
                                           </div>
                                           <div class="flexbasis">
                                            <input type="checkbox" id="stp_description" class="stp" (click)="enableDisableButton()"  name="export" value="stp_description" checked>
                                            <label class="sidebyside" (click)="checkBoxHandler('stp_description')">Description</label>
                                            
                                            </div>
                                           </div>  
                                            </div>

                                            <h4 style="font-weight: 700; cursor: pointer;" (click)="toggleCollapse('modelRepo')" ><span 
                                              class="arrow"
                                              [class.collapsed]="exportToExcelCollapsibles.modelRepo"></span>Model Repository</h4>
        
                                              <div
                                              [hidden]="exportToExcelCollapsibles.modelRepo"
                                              class="collapsible-content">
                                              <div style="display: flex; flex-basis:100%;">
                                             
                                            <input style="display: inline" (click)="selectModelRepoOptions()"  name="export" type="checkbox" id="modelRepo" value="modelRepo" checked> 	
                                         <label style="font-size:14px; font-weight: 400;" (click)="checkBoxHandler('modelRepo')">Select All</label> 	 </div>
                                         <br>
                                         
                                                <div class="flexdirection"> <div class="flexbasis">
                                               <input type="checkbox" id="mr_information_classification" class="modelRepo"  (click)="enableDisableButton()" name="export" value="mr_information_classification" checked>
                                               <label class="sidebyside" (click)="checkBoxHandler('mr_information_classification')">Information Classification</label>
                                               </div>
                                               <div class="flexbasis">
                                                <input type="checkbox" id="mr_stage_model_development" class="modelRepo" (click)="enableDisableButton()"  name="export" value="mr_stage_model_development" checked>
                                                <label class="sidebyside" (click)="checkBoxHandler('mr_stage_model_development')">Stage of Model Development</label>
                                                
                                                </div>
                                               </div>  

                                               <div class="flexdirection"> <div class="flexbasis">
                                                <input type="checkbox" id="mr_model_steward" class="modelRepo"  (click)="enableDisableButton()" name="export" value="mr_model_steward" checked>
                                                <label class="sidebyside" (click)="checkBoxHandler('mr_model_steward')">Model Steward</label>
                                                </div>
                                                <div class="flexbasis">
                                                 <input type="checkbox" id="mr_value_implementation_lead" class="modelRepo" (click)="enableDisableButton()"  name="export" value="mr_value_implementation_lead" checked>
                                                 <label class="sidebyside" (click)="checkBoxHandler('mr_value_implementation_lead')">Value and Implementation Lead</label>
                                                 
                                                 </div>
                                                </div>  

                                                <div class="flexdirection"> <div class="flexbasis">
                                                  <input type="checkbox" id="mr_country" class="modelRepo"  (click)="enableDisableButton()" name="export" value="mr_country" checked>
                                                  <label class="sidebyside" (click)="checkBoxHandler('mr_country')">Country</label>
                                                  </div>
                                                  <div class="flexbasis">
                                                   <input type="checkbox" id="mr_name_product" class="modelRepo" (click)="enableDisableButton()"  name="export" value="mr_name_product" checked>
                                                   <label class="sidebyside" (click)="checkBoxHandler('mr_name_product')">Name of Product</label>
                                                   
                                                   </div>
                                                  </div>  

                                                  <div class="flexdirection"> <div class="flexbasis">
                                                    <input type="checkbox" id="mr_indication" class="modelRepo"  (click)="enableDisableButton()" name="export" value="mr_indication" checked>
                                                    <label class="sidebyside" (click)="checkBoxHandler('mr_indication')">Indication/Disease</label>
                                                    </div>
                                                    <div class="flexbasis">
                                                     <input type="checkbox" id="mr_model_type" class="modelRepo" (click)="enableDisableButton()"  name="export" value="mr_model_type" checked>
                                                     <label class="sidebyside" (click)="checkBoxHandler('mr_model_type')">Model Type</label>
                                                     
                                                     </div>
                                                    </div> 

                                                    <div class="flexdirection"> <div class="flexbasis">
                                                      <input type="checkbox" id="mr_version_number" class="modelRepo"  (click)="enableDisableButton()" name="export" value="mr_version_number" checked>
                                                      <label class="sidebyside" (click)="checkBoxHandler('mr_version_number')">Version Number</label>
                                                      </div>
                                                      <div class="flexbasis">
                                                       <input type="checkbox" id="mr_global" class="modelRepo" (click)="enableDisableButton()"  name="export" value="mr_global" checked>
                                                       <label class="sidebyside" (click)="checkBoxHandler('mr_global')">Global/Adaptation</label>
                                                       
                                                       </div>
                                                      </div> 

                                                </div>
                                    
                                
                    
                     
                            <br> <label style="font-size: 14px;float:left; font-weight: 700; color:teal; cursor: default;" id="info"></label>  <label style="font-size: 14px;float:left; margin-left:20px; font-weight: 700; color:teal; cursor: default;" id="downloading" ></label> <br>   <br> 
                           
                          <div style="float: right; margin-top: -43px;"> 
                            
                  <button class="exportToExcelBtnCancel" (click)="closeMainTab()">Close</button> <button id="exportToExcelBtn"  (click)="exportToExcel()">Export</button>
               
                              </div>

        </form>
      <!-- If the content exceeds the container height, a scroll bar will appear -->
    </div>